import {useSelector} from "react-redux";
import {Container} from "react-bootstrap";
import React from "react";
import AFormSummaryFieldPending from "../../templates/AFormSummaryFieldPending";
import AFormSummaryFieldDone from "../../templates/AFormSummaryFieldDone";
import AFormSummaryHint from "../../templates/AFormSummaryHint";
import AFormSummaryHeader from "../../templates/AFormSummaryHeader";
import AFormSummaryNextStep from "../../templates/AFormSummaryNextStep";
import AFormSummarySectionHeading from "../../templates/AFormSummarySectionHeading";
import AFormSummarySection from "../../templates/AFormSummarySection";

//@todo trigger at dispatch? cos before initialisation crashes

export default function UserFormSummary({...props}) {

    const formWizard = useSelector(state => state.forms[props.fid].wizard)

    const email = useSelector(state => state.forms[props.fid].data.email)
    const fname = useSelector(state => state.forms[props.fid].data.fname)
    const lname = useSelector(state => state.forms[props.fid].data.lname)
    const gender = useSelector(state => state.forms[props.fid].data.gender)
    const dob = useSelector(state => state.forms[props.fid].data.dob)
    const title = useSelector(state => state.forms[props.fid].data.title)
    const profession = useSelector(state => state.forms[props.fid].data.profession)
    const company = useSelector(state => state.forms[props.fid].data.company)
    const physician = useSelector(state => state.forms[props.fid].data.physician)
    const referrer = useSelector(state => state.forms[props.fid].data.referrer)

    return <Container className='fs-4 text-light-bg-darkGradient p-5 rounded-3 shadow-lg position-relative'>

        <AFormSummaryHeader
            title='Profile Summary'
            text={email ?
                <AFormSummaryFieldDone text={email} label='Email address'/>
                :
                <AFormSummaryFieldPending text="You're Adding a New Person"/>
            }
        />

        {
            email ? (formWizard && formWizard.activeIndex === 0 ? <AFormSummaryNextStep text='Next : Profile details'/> : '')
                :''
                // <AFormSummaryHint text='What is the email address of this person?'/>
        }
        {
            !formWizard || formWizard.activeIndex >= 1 ?
                <AFormSummarySection>
                    <AFormSummarySectionHeading text='About this Person'/>
                    {
                        fname ? <AFormSummaryFieldDone text={`${fname} ${lname}`} label='Name'/>
                            : <AFormSummaryFieldPending text='First and Last Name'/>
                    }
                    {
                        gender ? <AFormSummaryFieldDone text={`${gender}`} label='Gender'/>
                            : <AFormSummaryFieldPending text='Gender'/>
                    }
                    {
                        dob ? <AFormSummaryFieldDone text={`${dob}`} label='Date of Birth'/>
                            : <AFormSummaryFieldPending text='Date of Birth'/>
                    }

                    {
                        fname && gender && dob && formWizard ?
                            <AFormSummaryNextStep text='Next : Other Information'/>
                            : ''
                    }
                </AFormSummarySection>
                : ''
        }
        {
            !formWizard || formWizard.activeIndex >= 2 ?
                <AFormSummarySection>
                    <AFormSummarySectionHeading text='Other Information'/>
                    {
                        title ? <AFormSummaryFieldDone text={physician} label='Title'/>
                            : <AFormSummaryFieldPending text='Title'/>
                    }
                    {
                        profession ? <AFormSummaryFieldDone text={profession} label='Profession'/>
                            : <AFormSummaryFieldPending text='Profession'/>
                    }
                    {
                        company ? <AFormSummaryFieldDone text={company} label='Company'/>
                            : <AFormSummaryFieldPending text='Company'/>
                    }

                    {
                        title && profession && company && formWizard ?
                            <AFormSummaryNextStep text='Next : Referral & Physician'/>
                            : ''
                    }
                </AFormSummarySection> : ''
        }
        {
            !formWizard || formWizard.activeIndex >= 3 ?
                <AFormSummarySection>
                    <AFormSummarySectionHeading text='Referral & Physician'/>
                    {
                        physician ? <AFormSummaryFieldDone text={physician} label='Physician'/>
                            : <AFormSummaryFieldPending text={`Who is ${fname}s' physician?`}/>
                    }
                    {
                        referrer ? <AFormSummaryFieldDone text={`${gender}`} label='Referrer'/>
                            : <AFormSummaryFieldPending text={`Who referred ${fname}?`}/>
                    }

                    {
                        fname && gender && dob && (formWizard && formWizard.activeIndex === formWizard.maxIndex) ?
                            <AFormSummaryHint text={`Click Save to add ${fname} ${lname}`}/>
                            : ''
                    }
                </AFormSummarySection> : ''
        }

    </Container>
}
