import {FormSelect} from "react-bootstrap";
import {formControlBlur, formControlChange, formControlFocus} from "./actions";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

export default function _AFormControlTypeSelect({...control}) {

    const dispatch = useDispatch()
    control = useSelector(state => state.forms[control.fid].controls[control.cid])

    let value = control.value === undefined ? control.defaultValue : control.value

    let options = Object.keys(control.options).map((key, index) => {

        if (control.value === undefined && key === control.defaultValue) {
            value = key
        }

        return <option value={key} key={index}>
            {control.options[key]}
        </option>
    })


    return <>
        <FormSelect
            value={value}
            size={control.size}
            autoFocus={control.isFocused}

            placeholder={
                control.isLabelFloating ? control.placeholder :
                    (control.isPlaceholderVisible ? control.placeholder : '')
            }

            isValid={control.isValid}
            isInvalid={control.isInvalid}
            readOnly={control.isReadOnly}

            disabled={control.isDisabled}

            onFocus={() => {
                dispatch(formControlFocus(control))
            }}
            onBlur={(e) => {
                dispatch(formControlBlur(control, e.target.value))
            }}
            onChange={(e) => {
                dispatch(formControlChange(control, e.target.value))
            }}
        >
            {options}
        </FormSelect>
    </>
}
