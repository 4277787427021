import {DsynrFormOptions} from "../options";
import {formDataRecap, formDataUpdate, formErrorsUpdate} from "../form/actions";
import {lf} from "../../../Utils/debug";
import {getFormControl, isFromControlTypeDateTime, isFromControlTypeEmail, isFromControlTypeOption, isFromControlTypeSelect, isFromControlTypeText} from "../utils";
import {getState} from "../../../Utils/react";
import {isValidEmail} from "../../../Utils/misc";

//Thunk actions should be dispatchable from anywhere with ease.
// Define only pure (non-thunk) actions in here.
// These should be called only by the thunk actions.
export const ControlActions = {
    enlistControl: control => {
        return {
            type: DsynrFormOptions.Form.Control.Actions.enlistControl,
            payload: {
                control: control,
            }
        }
    },
    updateControlProps: (control, props) => {
        //@todo update multiple props in single dispatch
        // lf('updateControlPropS...................', control, props)
        return {
            type: DsynrFormOptions.Form.Control.Actions.updateControlProps,
            payload: {
                control: control,
                props: props //{}
            }
        }
    },
    updateControlProp: (control, propName, propVal) => {
        // lf('updateControlProp...................', control, propName, propVal)
        return {
            type: DsynrFormOptions.Form.Control.Actions.updateControlProp,
            payload: {
                control: control,
                prop: propName,
                val: propVal,
            }
        }
    }
}

/**
 * formControlFocus is WORKING with getFormControl
 * @notice getFormControl is used instead of directly accessing control.props passed via param as all the actions happen synchronously
 * this results in the succeeding dispatches using the same prop values passed in the original dispatch!
 * as a result only the last dispatch is truly effective.
 * @todo is there a better way? use promise/await? Also, this maybe happening in other chained actions...
 * @param control
 * @returns {(function(*): void)|*}
 */
export const formControlFocus = control => {
    return function (dispatch) {
        if (control.isVirgin) {
            dispatch(ControlActions.updateControlProp(control, 'isVirgin', false))
        } else {
            dispatch(formControlNeutralise(control))
        }
        dispatch(ControlActions.updateControlProp(getFormControl(control.fid, control.cid), 'isFocused', true))
        if (control.isHighlightedOnFocus) {
            dispatch(formControlToggleHighlight(getFormControl(control.fid, control.cid)))
        }

        if (control.onFocus) {
            control.onFocus()
        }
    }
}

export const formControlChange = (control, newVal) => {
    // lf('formControlChange...................', control, newVal, typeof newVal)
    return function (dispatch) {
        dispatch(formControlSetVal(control, newVal))

        control = getFormControl(control.fid, control.cid)


        if (!control.isVirgin) {
            if (control.isRealTimeUpdate || control.hasRealTimeValidation) {
                dispatch(ControlActions.updateControlProp(control, 'isChanged', control.defaultValue != newVal))
            }
        }

        control = getFormControl(control.fid, control.cid)
        if (control.hasRealTimeValidation) {
            dispatch(formControlValidate(control))
        }

        control = getFormControl(control.fid, control.cid)
        if (!control.hasRealTimeValidation && control.isRealTimeUpdate) {
            dispatch(formDataUpdate(control))
        }

        if (control.onChange) {
            control.onChange(newVal)
        }
    }
}

export const formControlBlur = (control) => {
    return function (dispatch) {

        dispatch(ControlActions.updateControlProp(control, 'isFocused', false))

        if (control.isHighlightedOnFocus) {
            dispatch(formControlToggleHighlight(getFormControl(control.fid, control.cid), false))
        }

        if (isFromControlTypeText(control)) {
            control = getFormControl(control.fid, control.cid)
            if (control.isRequired && control.defaultValue !== '' && control.value === '') {
                dispatch(formControlSetDefaultVal(control))
            }
            control = getFormControl(control.fid, control.cid)
            dispatch(ControlActions.updateControlProp(getFormControl(control.fid, control.cid), 'isChanged', control.defaultValue !== control.value))
        }
        dispatch(formControlValidate(getFormControl(control.fid, control.cid)))

        if (control.onBlur) {
            control.onBlur()
        }
    }
}

export const formControlToggleValidity = (control, isValid = true) => {
    return function (dispatch) {
        dispatch(ControlActions.updateControlProp(control, 'isValid', isValid))
        dispatch(ControlActions.updateControlProp(getFormControl(control.fid, control.cid), 'isInvalid', !isValid))
    }
}

export const formControlNeutralise = control => {
    return function (dispatch) {
        dispatch(ControlActions.updateControlProp(control, 'isValid', undefined))
        dispatch(ControlActions.updateControlProp(getFormControl(control.fid, control.cid), 'isInvalid', undefined))
    }
}

export const formControlToggleVisibility = (control, isVisible = true) => {
    return function (dispatch) {
        dispatch(ControlActions.updateControlProp(control, 'isVisible', isVisible))
    }
}

export const formControlToggleHighlight = (control, isHighlighted = true) => {
    return function (dispatch) {
        dispatch(ControlActions.updateControlProp(control, 'isHighlighted', isHighlighted))
    }
}

//@todo validate email if supplied when non-mandatory
//@todo bug - form has errors on alternative char in email@domain(text untested?) field post email validation
export const formControlValidate = (control) => {
    return function (dispatch) {

        let isValid = true

        //check regardless of isChanged
        if (control.isRequired) {
            if (isFromControlTypeText(control)) {
                if (control.defaultValue === '' && control.value === '') {
                    isValid = false
                } else if (isFromControlTypeEmail(control)) {
                    isValid = isValidEmail(control.value)
                }
            } else if (isFromControlTypeOption(control)) {
                if (control.defaultValue === false && control.checked === false) {
                    isValid = false
                }
            } else {
                isValid = control.value !== control.defaultValue || (isFromControlTypeOption(control) && control.defaultValue !== control.checked)
            }
        }

        if ((((control.defaultValue === '' || (isFromControlTypeOption(control) && control.defaultValue === false)) && !control.isChanged) || control.isChanged) && control.isValidationVisible) {
            if (isValid) {
                dispatch(formControlToggleValidity(control))
            } else {
                dispatch(formControlToggleValidity(control, false))
            }
        }

        control = getFormControl(control.fid, control.cid)

        if (control.isChanged && isValid) {
            dispatch(formDataUpdate(control))
            dispatch(formErrorsUpdate(control, true))
        } else {
            dispatch(formErrorsUpdate(control))
            dispatch(formDataUpdate(control, true))
        }

        if (!control.isValidationVisible && control.isChanged) {
            dispatch(formControlNeutralise(control))
        }
    }
}

/**
 * @warning This will set the value of the control directly without triggering change event. Do not use directly unless intentional.
 * @see formControlChange
 * @param control
 * @param value
 * @returns {(function(*): void)|*}
 */
export const formControlSetVal = (control, value) => {
    return function (dispatch) {
        if (isFromControlTypeOption(control)) {
            dispatch(ControlActions.updateControlProp(control, 'checked', value))
        } else {
            dispatch(ControlActions.updateControlProp(control, 'value', value))
        }
    }
}

/**
 * @warning This will reset the value of the control directly to its default value without triggering change event. Do not use directly unless intentional.
 * @see formControlReset
 * @param control
 * @returns {(function(*): void)|*}
 */
export const formControlSetDefaultVal = control => {
    return function (dispatch) {
        if (control.isVirgin) {
            if (isFromControlTypeOption(control)) {
                dispatch(ControlActions.updateControlProp(control, 'defaultValue', control.defaultValue === true))
            } else if (isFromControlTypeDateTime(control)) {
                const dateTime = new Date()
                dispatch(ControlActions.updateControlProp(control, 'defaultValue', dateTime.toString()))
            }
        }

        control = getFormControl(control.fid, control.cid)
        dispatch(formControlSetVal(control, control.defaultValue))
    }
}

export const formControlReset = (control, updateFormData = true) => {
    // lf('formControlReset...................', control)
    return function (dispatch) {
        dispatch(formControlSetDefaultVal(control))
        dispatch(formControlNeutralise(getFormControl(control.fid, control.cid)))
        dispatch(ControlActions.updateControlProp(getFormControl(control.fid, control.cid), 'isChanged', false))
        if (updateFormData) {
            dispatch(formDataUpdate(control, true))
        }
    }
}

export const formControlVirginise = control => {
    return function (dispatch) {
        dispatch(ControlActions.updateControlProp(control, 'isVirgin', true))
    }
}
