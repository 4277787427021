import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, FormControl, FormGroup} from "react-bootstrap";
import _AFormControl from "./_/control/_AFormControl";
import _AFormControlLabel from "./_/control/_AFormControlLabel";
import _AFormControlExtraInfo from "./_/control/_AFormControlExtraInfo";
import {lf} from "../Utils/debug";
import {getFormControl, isFromControlTypeOption, isFromControlTypeText} from "./_/utils";
import {ControlActions, formControlReset, formControlSetDefaultVal} from "./_/control/actions";
import {formDataRecap} from "./_/form/actions";

/**
 * @see DsynrFormOptions.Form.Control.Props for available controlProps
 * @usage DsynrFormControl {...customControlProps}
 * @param control
 * @returns {JSX.Element}
 * @constructor
 */
export default function DsynrFormControl({...control}) {


    const dispatch = useDispatch()

    let isSelfResetVisible = true
    if (isFromControlTypeOption(control)) {
        isSelfResetVisible = false
    }

    if (!getFormControl(control.fid, control.cid)) {
        dispatch(ControlActions.enlistControl(control))
        dispatch(formControlSetDefaultVal(control))
    }

    control = useSelector(state => state.forms[control.fid].controls[control.cid])
    // lf('DsynrFormControprops...........',control)

    const formControl = <_AFormControl {...control}/>

    return <>
        {control.isVisible ?
            <div
                className={`${control.isHighlighted ? control.highlightCls : (control.isValidationVisible && control.isInvalid ? control.errorCls : control.cls)}`}>
                <FormGroup controlId={control.cid}>

                    {
                        control.label ?
                            <>
                                <_AFormControlLabel control={formControl}
                                                    {...control}
                                />
                            </>
                            :
                            <>{formControl}</>
                    }
                </FormGroup>

                {
                    isSelfResetVisible && control.hasSelfReset && control.isChanged ?
                        // <div className='text-center' style={{width: '50px'}}>
                        <Button
                            variant={control.selfResetVariant}
                            className={control.selfResetCls}
                            onClick={
                                () => {
                                    dispatch(formControlReset(control))
                                }}
                        >{control.selfResetTxt}
                        </Button>
                        // </div>
                        : ''
                }

                {
                    control.isInvalid && control.isValidationVisible ?'' //@todo remove?
                        // <Alert variant={"danger"}>{control.errorTip}</Alert>
                        : ''
                }

                <_AFormControlExtraInfo {...control}/>
            </div>
            : ''
        }
    </>
}
