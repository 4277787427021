import {getRandomArrayItem} from "../DSYNR/Utils/calculus";

/**
 * @todo...fix logic
 * @param name
 * @returns {`${string} ${string}${string}`}
 * @constructor
 */
export const AGreeting = ({name}) => {
    let dateTime = new Date().getHours()
    const punctuation = [',', '!',];
    let greeting

    switch (dateTime) {
        case (dateTime < 12):
            greeting = getRandomArrayItem(['Good morning', 'Morning'])
            break
        case (dateTime >= 12 && dateTime <= 17):
            greeting = getRandomArrayItem(['Good afternoon', 'Good day'])
            break
        case (dateTime > 17 && dateTime <= 21):
            greeting = getRandomArrayItem(['Good evening', 'Evening'])
            break
        default:
            greeting = getRandomArrayItem(['Hello', 'Hey', 'Hi', 'Greetings'])
    }
    return `${greeting} ${name}${getRandomArrayItem(punctuation)}`;
}
