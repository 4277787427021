import {Card, ListGroup} from "react-bootstrap";
import ASpinner from "../app/ASpinner";
import React from "react";

export default function ADashboardWidget({ico, title, summary, body}) {
    return <>
        <h3 className="fw-lighter display-6 text-secondary mb-4">
            <span className="text-secondary opacity-25">{ico}</span> {title}
        </h3>
        <Card className="border-0 rounded shadow-sm">
            <Card.Body>
                {
                    body ? body
                        :
                        <ListGroup variant={"flush"}>
                            {summary || <ASpinner/>}
                        </ListGroup>
                }

            </Card.Body>
        </Card>
    </>
}
