import thunkMiddleware from 'redux-thunk'
// import promise from 'redux-promise-middleware' @todo
import {applyMiddleware, compose, createStore} from 'redux'
import loggerMiddleware from './middleware/logger'
import {routerMiddleware} from 'connected-react-router'
import createRootReducer from "./reducers";
import {createBrowserHistory} from "history";
import {AppInitialState} from "./state";
// import monitorReducersEnhancer from './enhancers/monitorReducer'

export default function configureStore(preloadedState) {
    const middlewares = [thunkMiddleware, routerMiddleware(HISTORY), loggerMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    //https://stackoverflow.com/questions/57628279/composeenhancers-is-not-a-function-in-reactjs
    // const reduxDevToolsExt = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
    // const enhancers = [middlewareEnhancer, monitorReducersEnhancer, reduxDevToolsExt]

    const enhancers = [middlewareEnhancer]

    const composedEnhancers = compose(...enhancers)

    return createStore(
        createRootReducer(),
        // createRootReducer(HISTORY),
        preloadedState,
        composedEnhancers,
    )
}

export const HISTORY = createBrowserHistory()
//@todo load state from localstorage
export const STORE = configureStore()
