import React from "react";
import {useDispatch} from "react-redux";
import AMakePayment from "./AMakePayment";
import AFormSectionHeading from "../../templates/AFormSectionHeading";

export default function AFormPaymentSection() {

    const dispatch = useDispatch()

    return <section className='pt-5 px-5 pb-3'>
        <AFormSectionHeading heading='Payment & Confirmation'/>
        <AMakePayment amount={150}/>
    </section>
}
