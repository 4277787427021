import {DsynrFormOptions} from "./options";
import {lf} from "../../Utils/debug";
import {updateObj} from "../../Utils/obj";
import {updateForm, updateFormControlProp, updateFormControls, updateFormWizard, updateFormWizardProp} from "./utils";
import {updateState} from "../../Utils/react";

export default function DsynrForms(forms = {}, action) {
    if (action.payload) {

        const payload = action.payload
        lf('payload@DsynrForms:::', payload)

        let form, fid, controls, control, cid
        if (payload.form !== undefined || payload.control !== undefined) {

            if (payload.control !== undefined) {
                control = payload.control
                fid = control.fid
                form = forms[fid]
            } else if (payload.form !== undefined) {
                form = payload.form
                fid = form.fid
            }

            switch (action.type) {

                case DsynrFormOptions.Form.Actions.enlistForm:
                    return updateObj(forms, {[fid]: payload.form})

                case DsynrFormOptions.Form.Actions.updateFormProp:
                    return updateState(forms, fid,
                        updateForm(forms[form.fid], payload.prop, payload.val)
                    )

                case DsynrFormOptions.Form.Wizard.Actions.updateWizardProp:
                    return updateState(forms, fid,
                        updateForm(form, 'wizard',
                            updateFormWizard(form,
                                updateFormWizardProp(form.wizard, payload.prop, payload.val)
                            )
                        )
                    )

                case DsynrFormOptions.Form.Control.Actions.enlistControl:
                    return updateState(forms, fid,
                        updateForm(form, 'controls',
                            updateFormControls(
                                form,
                                control
                            )
                        )
                    )

                case DsynrFormOptions.Form.Control.Actions.updateControlProps:
                    //@todo update multiple props in single dispatch
                    return forms

                case DsynrFormOptions.Form.Control.Actions.updateControlProp:
                    // let updateFormControlPropx = updateFormControlProp(control, payload.prop, payload.val)
                    // let updateFormControlsx = updateFormControls(
                    //     form,
                    //     updateFormControlPropx
                    // )
                    // let updateFormxx = updateForm(form, 'controls',
                    //     updateFormControlsx
                    // )
                    // let xxx = updateState(forms, fid,
                    //     updateFormxx
                    // )
                    // lf('xxx.................', payload.prop, payload.val, updateFormControlPropx, updateFormControlsx, updateFormxx, xxx)
                    // return xxx
                    // alert(payload.prop+'='+payload.val)
                    // lf('XXXXXXXXXXXX...',s)
                    return updateState(forms, fid,
                        updateForm(form, 'controls',
                            updateFormControls(
                                form,
                                updateFormControlProp(control, payload.prop, payload.val)
                            )
                        )
                    )
            }
        }

    }
    return forms
}
