import {lf} from "../../DSYNR/Utils/debug";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import React from "react";
import {AppOptions} from "../../_/options";
import ASpinner from "../ASpinner";
import {useDispatch, useSelector} from "react-redux";
import AddNewBtn from "./AddNewBtn";
import ADashboardWidget from "../../templates/ADashboardWidget";
import {changeScreen, hideMenuPanel, hideNotificationPanel} from "../../_/actions/ui";

export default function AOverview() {
    lf('AOverview')

    const dispatch = useDispatch()

    const icoOverview = useSelector(state => state.app.data.overview.ico)
    const overview = useSelector(state => state.app.data.overview)

    const summary = Object.keys(overview).map((item, i) => {
        if (AppOptions[item] && item) {
            const oi = overview[item]
            const oiIco = AppOptions[item].ico
            const oiTitle = AppOptions[item].screenTitle

            return <ListGroup.Item as='div' className='p-0' key={i} action>
                <div className='hand p-3 d-inline-block w-75' onClick={() => {
                    dispatch(changeScreen(item))
                    // dispatch(hideMenuPanel())
                }}>
                    <span className="fs-4 opacity-25">{oiIco}</span>
                    <span className="lead">
                    <span className="ms-3 me-2 text-muted">{oi}</span>
                        {oiTitle}
                </span>
                </div>

                <div className='d-inline-block mt-2'>
                    {
                        AppOptions[item].isNewable ? <AddNewBtn what={item} cls=''/> : ''
                    }
                </div>


            </ListGroup.Item>
        }
    })

    return <ADashboardWidget
        title='Manage'
        ico={icoOverview}
        summary={summary}/>
}
