import {lf} from "../../DSYNR/Utils/debug";
import {useDispatch, useSelector} from "react-redux";
import {DsynrFormOptions} from "../../DSYNR/DsynrForm/_/options";
import {getState} from "../../DSYNR/Utils/react";
import AFormWithSummary from "../../templates/AFormWithSummary";
import DsynrForm from "../../DSYNR/DsynrForm";
import AFormSectionHeading from "../../templates/AFormSectionHeading";
import AFormSelectUser from "./widgets/AFormSelectUser";
import React from "react";
import DsynrFormControl from "../../DSYNR/DsynrForm/DsynrFormControl";
import {getAppFormControlDefaults} from "../../_/utils";
import AHint from "../../app/AHint";
import UserFormSummary from "./UserFormSummary";
import {updateAppStatus} from "../../_/actions/app";
import {APIS} from "../../_/options";
import AFormSection from "../../templates/AFormSection";

export default function NewUserForm() {
    lf('NewUserForm')

    const token = useSelector(state => state.app.data.account.token)
    const user = useSelector(state => state.app.data.user)

    const genderOptions = useSelector(state => state.app.options.user.gender)
    const titleOptions = useSelector(state => state.app.options.user.title)
    const professionOptions = useSelector(state => state.app.options.user.profession)

    const formPostURL = APIS.add

    const dispatch = useDispatch()

    const formSubmissionSuccessful = () => {
        lf('formSubmissionSuccessful', getState('forms'))
        // alert('formSubmissionSuccessful')
    }

    const formSubmissionError = () => {
        lf('formSubmissionError', getState('forms'))
        dispatch(updateAppStatus('onSubmitError...'))
    }

    const fid = 'newUserForm'

    const formProps = {
        ...DsynrFormOptions.Form.Props,
        fid: fid,
        token: token,
        submitURL: formPostURL,
        isSubmitBtnVisibleDuringWizard: false,
        resetBtnTxt: 'Cancel & Restart',
        progressBarRootCls: 'bg-white rounded my-3 overflow-hidden',
        onSubmitSuccess: formSubmissionSuccessful,
        onSubmitError: formSubmissionError,
        // isWizard: false,
        // isVisible: false,
        submitBtnTxt: 'Save',
    }

    const fname = {
        ...getAppFormControlDefaults(),
        cid: 'fname',
        fid: fid,
        type: DsynrFormOptions.Form.Control.Types.text,
        label: 'First Name',
        isRequired: true,
    }

    const lname = {
        ...getAppFormControlDefaults(),
        cid: 'lname',
        fid: fid,
        type: DsynrFormOptions.Form.Control.Types.text,
        label: 'Last Name',
        isRequired: true,
    }

    const email = {
        ...getAppFormControlDefaults(),
        cid: 'email',
        fid: fid,
        isRequired: true,
        hasRealTimeValidation: true,
        type: DsynrFormOptions.Form.Control.Types.email,
        placeholder: 'Enter email here...',
        extraInfo: <AHint msg='The email address can be used as the username'/>,
        extraInfoCls: 'm-1',
    }

    const gender = {
        ...getAppFormControlDefaults(),
        cid: 'gender',
        fid: fid,
        label: 'Gender',
        options: genderOptions,
    }

    const dob = {
        ...getAppFormControlDefaults(),
        cid: 'dob',
        fid: fid,
        type: DsynrFormOptions.Form.Control.Types.dateTime,
        extraInfo: 'Date of Birth',
    }

    const title = {
        ...getAppFormControlDefaults(),
        cid: 'title',
        fid: fid,
        label: 'Title',
        options: titleOptions,
    }

    const profession = {
        ...getAppFormControlDefaults(),
        cid: 'profession',
        fid: fid,
        label: 'Profession',
        options: professionOptions,
    }

    const company = {
        ...getAppFormControlDefaults(),
        cid: 'company',
        fid: fid,
        type: DsynrFormOptions.Form.Control.Types.text,
        label: 'Company',
        isLabelFloating: false,
        isLabelConjoined: true,
    }

    return <AFormWithSummary
        {...formProps}
        form={
            <DsynrForm {...formProps}>

                <AFormSection>
                    <AFormSectionHeading heading="Let's begin with the Email address of this person"/>
                    <DsynrFormControl {...email}/>
                </AFormSection>

                {/*@todo validate if email already exists in cms?*/}
                <AFormSection>
                    <AFormSectionHeading heading='About the Person'/>
                    <DsynrFormControl {...fname}/>
                    <DsynrFormControl {...lname}/>
                    <DsynrFormControl {...gender}/>
                    <DsynrFormControl {...dob}/>
                </AFormSection>

                <AFormSection>
                    <AFormSectionHeading heading='Other Information'/>
                    <DsynrFormControl {...title}/>
                    <DsynrFormControl {...profession}/>
                    <DsynrFormControl {...company}/>
                </AFormSection>

                <AFormSection>
                    <AFormSectionHeading heading='Referral & Physician'/>
                        <AFormSelectUser fid={fid} cid='physician' allowAddNew={false} label='Who is their Physician?'/>
                        <div className='mt-5'>
                            <AFormSelectUser fid={fid} cid='referrer' allowAddNew={false} label='Whom were they Referred by?'/>
                        </div>
                    {/*<div className='p-5'>*/}
                    {/*</div>*/}
                </AFormSection>


            </DsynrForm>
        }
        formSummary={
            <UserFormSummary {...formProps}/>
        }
    />
}
