import {l, lf} from "./debug";
import {stringify} from "querystring";

export const setObjAttr = (attr, val, obj) => {
    lf('setObjAttr', attr, val, obj)
    obj[attr] = val;
    return obj;
}

export const getObjKeys = obj => {
    return Object.keys(obj)
}

export const objHasAttr = (attrName, obj) => {
    lf('objHasAttr', attrName, obj)
    if (!obj.hasOwnProperty(attrName)) {
        l(`INVALID KEY :: ${attrName}`, obj);
        throw new Error(`INVALID KEY :: ${attrName}`);
    }
    return true;
}

export const updateObj = (oldObject, newValues) => {
    // Encapsulate the idea of passing a new object as the first parameter
    // to Object.assign to ensure we correctly copy data instead of mutating
    return Object.assign({}, oldObject, newValues)
}

export const updateItemInArr = (array, itemId, updateItemCallback) => {
    const updatedItems = array.map(item => {
        if (item.id !== itemId) {
            // Since we only want to update one item, preserve all others as they are now
            return item
        }

        // Use the provided callback to create an updated item
        const updatedItem = updateItemCallback(item)
        return updatedItem
    })

    return updatedItems
}

export const obj2query = (jsonObj) => {
    return stringify(jsonObj)
}
