import React from "react";
import {TiPointOfInterest} from "react-icons/all";

export default function AFormSummaryRow({
                                            text,
                                            textSize = 'fs-6',
                                            textCls = 'secondary',
                                            ico = <TiPointOfInterest/>,
                                            icoVariant = 'secondary'
                                        }) {
    return <>
        <div className={`lead l1 ${textSize}`}>
            <div className={`float-start me-2 fs-5 opacity-50 text-${icoVariant}`}>
                {ico}
            </div>
            <p className={`${textCls}`}>{text}</p>
        </div>
    </>
}
