import {AppOptions} from "../options";
import {l, lf} from "../../DSYNR/Utils/debug";
import {updateObj} from "../../DSYNR/Utils/obj";
import {ACTIONS} from "../actions";
import {AppInitialState} from "../state";

const switchNotDefined = (caseName, action) => {
    l(`SWITCH CASE NOT DEFINED FOR :: ${caseName}`, action)
}

export default function DsynrApp(app = AppInitialState, action) {
    lf('DsynrApp', app, action)

    // let app = app.app
    // let modals = app.modals
    let screens = app.screens
    // let notificationPanel = app.notificationPanel

    switch (action.type) {
        //works only for first level values
        case ACTIONS.SET:
            return updateObj(app, {[action.payload.what]: action.payload.val})

        case ACTIONS.update:
            let pw = action.payload.what
            let pv = action.payload.val
            let w = app[pw]
            for (const k in pv) {
                w = updateObj(w, {...w, [k]: pv[k]})
            }
            return updateObj(app, {...app, [pw]: w})

        case ACTIONS.loadScreen:
            return updateObj(app, {...app, 'currentScreen': action.payload.screenName})

        case ACTIONS.exitCurrentScreen:
            return updateObj(app, {...app, 'currentScreen': 'app'})


        case ACTIONS.updateScreenBody:
            const screenName = action.payload.screenName
            let screen = screens[screenName]
            screen = updateObj(screen, {...screen, body: action.payload.body})
            screens = updateObj(screens, {...screens, [screenName]: screen})
            return updateObj(app, {...app, screens: screens})

        case ACTIONS.clearAllNotifications:
            return updateObj(app, {...app, notifications: []})


        // case ACTIONS.apiFetched:
        //     const name = action.payload.name
        //     alert('apiFetched.....@todo?')
        //     return app

        default:
            // switchNotDefined(action.type, action)
            return app
    }
}
