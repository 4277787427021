import React from "react";
import {lf} from "../DSYNR/Utils/debug";
import {storeLocally} from "../DSYNR/Utils/storage";
import {set} from "../_/actions";
import {useDispatch, useSelector} from "react-redux";
import {VscSearch} from "react-icons/all";
import {getState} from "../DSYNR/Utils/react";
import DsynrFormControl from "../DSYNR/DsynrForm/DsynrFormControl";
import DsynrForm from "../DSYNR/DsynrForm";
import {Button} from "react-bootstrap";
import AHint from "./AHint";
import AGenericWidget from "../templates/AGenericWidget";
import {DsynrFormOptions} from "../DSYNR/DsynrForm/_/options";
import {getAppFormControlDefaults} from "../_/utils";
import {APIS} from "../_/options";

export default function ASearchForm() {
    lf('ASearchForm')

    const dispatch = useDispatch()

    const token = useSelector(state => state.app.data.account.token)

    const formPostURL = APIS.search

    const fid = 'searchForm'

    const formOptions = DsynrFormOptions.Form
    // const controlTypes = formOptions.Control.Types
    // const controlProps = formOptions.Control.Props
    const controlOptions = DsynrFormOptions.Form.Control
    const controlAvailableTypes = controlOptions.Types
    const controlDefaultProps = controlOptions.Props

    const controlProps = {
        ...getAppFormControlDefaults(),
        type: controlAvailableTypes.text,
        cid: 'search',
        fid: fid,
        label: <><VscSearch/> Quick Search</>,
        cls: 'p-1 ms-2 mt-2 rounded-3 bg-info bg-opacity-10',
        highlightCls: 'p-1 ms-2 mt-2 rounded-3 bg-darkGradient',
        hasSelfReset: false,
        isValidationVisible: false,
    }

    const formProps = {
        ...formOptions.Props,
        fid: fid,
        token: token,
        submitURL: formPostURL,
        isProgressVisible: false,
        isSubmitBtnVisible: false,
        isResetBtnVisible: false,
        isWizard: false,
        isStyled: false,
    }

    const submissionSuccessful = () => {
        // const forms = getState('forms')
        // const curFormID = forms['currentForm'].id
        // const curForm = forms[curFormID]
        // const curFormResponse = curForm.response

        // lf('submissionSuccessful for ' + curFormID, curFormResponse)

        // const responseJSON = curFormResponse.json
        // const responseJSONData = responseJSON.data

        // if (responseJSON.success) {
        // }
    }

    const submissionError = () => {
        lf('submissionError', getState('forms'))
        //@todo error state....?
    }

    return <AGenericWidget
        bgCls='ms-4'
        body={
            <DsynrForm {...formProps}>
                <DsynrFormControl {...controlProps}/>
            </DsynrForm>
        }
    />
}
