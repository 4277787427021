import {lf} from "../DSYNR/Utils/debug";
import {Col, Container, Image, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import AProgressBar from "./AProgressBar";
import React from "react";
import {set} from "../_/actions";
import NetworkBadge from "./NetworkBadge";
import ANotificationBadge from "./ANotificationBadge";
import {FaWifi, IoNotifications, RiSignalWifiOffFill, VscSearch} from "react-icons/all";
import ASearchForm from "./ASearchForm";
import {AppOptions} from "../_/options";
import {hideMenuPanel, hideNotificationPanel, showMenuPanel, showNotificationPanel} from "../_/actions/ui";
import {updateAppStatus} from "../_/actions/app";

export default function AppHeader() {
    lf('AppHeader');

    const dispatch = useDispatch()

    const isDashboardVisible = useSelector(state => state.app.now.isDashboardVisible)
    const isPanelVisible = useSelector(state => state.app.now.isNotificationsVisible)

    const dsynr = AppOptions.dsynr
    const account = useSelector(state => state.app.data.account)

    const currentStatus = useSelector(state => state.app.now.currentStatus)

    let currentScreen = useSelector(state => state.app.now.currentScreen)
    let screenIco = AppOptions[currentScreen].ico || false
    let screenTitle = AppOptions[currentScreen].screenTitle || false

    return <Container as={"header"} fluid className="bg-translucent bg-white bg-opacity-50 px-4 position-fixed top z3">
        <Row>

            <Col xs={7} md={4}>

                <Image src={account.logo || dsynr.logo} className="float-start hand mt-3 bg-darkGradient"
                       roundedCircle width="50" height="50"
                       onClick={() => {
                           isDashboardVisible ? dispatch(hideMenuPanel()) : dispatch(showMenuPanel())
                       }}/>

                <div className='float-start position-relative'>
                    <ASearchForm/>
                </div>
            </Col>

            <Col className="d-none d-md-block text-center">
                <h1 className="fs-3 fw-light mt-3 mb-0 hand" onClick={() => {
                    isDashboardVisible ? dispatch(hideMenuPanel()) : dispatch(showMenuPanel())
                }}><span className="opacity-25">{screenIco}</span> {screenTitle}</h1>
                <div className="d-inline-block fs-6 font-monospace l1">{currentStatus}</div>
            </Col>

            <Col xs={5} md={4}>

                <div className="float-end fs-4 p-3 ms-3 hand"
                     onClick={() => {
                         isPanelVisible ? dispatch(hideNotificationPanel()) : dispatch(showNotificationPanel())
                     }}>
                    <ANotificationBadge/>
                </div>

                <NetworkBadge/>

            </Col>

            <AProgressBar/>

        </Row>
    </Container>
}
