import {lf} from "../../DSYNR/Utils/debug";
import {useSelector} from "react-redux";
import {Container, Image} from "react-bootstrap";
import React from "react";
import {BsCalendar, FaHandPointRight, ImPointRight, IoHourglassOutline, IoIosCheckmarkCircle, IoMdTime} from "react-icons/all";
import {AppOptions} from "../../_/options";

//@todo trigger at dispatch? cos before initialisation crashes
export default function AppointmentFormSummary({...props}) {
    lf('AppointmentFormSummary')

    const dsynr = AppOptions.dsynr
    const account = useSelector(state => state.app.data.account)
    const profile = useSelector(state => state.app.data.profile)

    const curFormData = useSelector(state => state.forms[props.fid].data) || false

    //@todo users should not be categorised by roles for easy ref. let roles be nested within user
    // const user = useSelector(state => state.app.users[curFormData.user]) || false
    const user = useSelector(state => state.app.data.users[curFormData.user]) || false
    // const service = useSelector(state => state.app.data.service[curFormData.service]) || false
    const service = false

    return <Container className='text-light fs-4 bg-darkGradient p-5 rounded-3 shadow-lg position-relative'>

        <Image src={account.logo || dsynr.logo} roundedCircle width="60" height="60" className="position-absolute left bg-darkGradient" style={{left: '50%', marginLeft: '-30px', top: '-25px'}}/>

        <h2 className="display-5 text-center fw-lighter l1 mt-3 mb-5 text-lightGradient">Appointment Summary</h2>

        {
            !user && !service ?
                <div className='text-center'>
                    <p className='lead fs-3'>{profile.fname}, Let's get started!</p>
                    <p className='lh-1 fs-5 text-info'>Select the patient you're booking<br/>this appointment for<FaHandPointRight className='ms-2 fs-3'/></p>
                </div> :

                <div>

                    <p className='fw-light'>
                        <span className='text-lightGradient fs-5 d-table mb-2'>This appointment is for</span>
                        {user.img ? <Image src={user.img} rounded width="75" height="75" className='me-3'/> : ''}
                        {user.fname} {user.lname}
                    </p>

                    <p className='mt-4 lead fs-4'>
                        <span className='text-lightGradient fs-5'>Service & Session Duration</span><br/>
                        {service ? <><IoIosCheckmarkCircle className='me-2 fs-3 text-info'/> {service}</> : <><FaHandPointRight className='me-2 fs-3'/> Pick a service</>}
                        <br/>{curFormData.duration ? <><IoIosCheckmarkCircle className='me-2 fs-3 text-info'/> {curFormData.duration}</> : <><FaHandPointRight className='me-2 fs-3'/> Set the session duration</>}
                    </p>

                    {
                        true ?
                            <p className='lead fs-3'>
                                <span className='text-lightGradient fs-5'>Date & Time</span><br/>
                                <BsCalendar className='me-2 fs-3'/> {curFormData.date ? curFormData.date : 'Choose a date'}
                                <br/><IoMdTime className='me-2 fs-3'/> {curFormData.time ? curFormData.time : 'Select the time'}
                            </p> : ''
                    }

                </div>
        }
    </Container>
}
