import React from "react";
import {useSelector} from "react-redux";
import {AppOptions} from "../_/options";
import {FaHandPointRight} from "react-icons/all";

export default function AFormSummaryNextStep({text}) {

    return <div className='mt-4 py-2 px-3 l1 bg-warning bg-opacity-25 rounded-end text-muted fs-6'>
        <FaHandPointRight className='me-2 fs-3x'/>
        {text}
    </div>
}
