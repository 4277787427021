import {Spinner} from "react-bootstrap";
import {lf} from "../DSYNR/Utils/debug";

export default function ASpinner({message}) {
    lf('ASpinner', message)
    return <div className="text-center">
        <Spinner size={"sm"} variant="secondary" animation="border" role="status"/>
        <pre className="m-0">{message ? message : 'Loading...'}</pre>
    </div>
}
