import {FormControl} from "react-bootstrap";
import {formControlBlur, formControlChange, formControlFocus} from "./actions";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

export default function _AFormControlTypeText({conditionalAttributes, ...control}) {

    const dispatch = useDispatch()
    control = useSelector(state => state.forms[control.fid].controls[control.cid])

    // let val = control.value || control.defaultValue
    // if (control.isFocused && control.isChanged && control.value === '') {
    //     val = control.value
    // }

    return <>
        <FormControl
            type={control.type}
            value={control.value}
            size={control.size}
            autoFocus={control.isFocused}

            placeholder={
                control.isLabelFloating ? control.placeholder :
                    (control.isPlaceholderVisible ? control.placeholder : '')
            }

            isValid={control.isValid}
            isInvalid={control.isInvalid}

            plaintext={control.isPlaintext}
            readOnly={control.isReadOnly}

            disabled={control.isDisabled}

            onFocus={() => {
                dispatch(formControlFocus(control))
            }}
            onBlur={() => {
                dispatch(formControlBlur(control))
            }}
            onChange={(e) => {
                dispatch(formControlChange(control, e.target.value))
            }}

            {...conditionalAttributes} />
    </>
}
