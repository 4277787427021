import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import {getPortal} from "./_/utils";
import {portalInitialise, portalToggleVisibility} from "./_/actions";
import {lf} from "../Utils/debug";

/**
 * @see DsynrPortalOptions.Props for available portalProps
 * @usage DsynrPortal {...customPortalProps}
 * @param portal
 * @returns {JSX.Element}
 * @constructor
 */
export default function DsynrPortal({...portal}) {

    lf('DsynrPortal', portal)

    const dispatch = useDispatch()

    if (!getPortal(portal.pid)) {
        dispatch(portalInitialise(portal))
    }

    portal = useSelector(state => state.portals[portal.pid])


    return <Modal
        onHide={() => {
            if (portal.isClosable) {
                dispatch(portalToggleVisibility(portal, false))
            }
        }}
        scrollable={portal.isScrollable}
        fullscreen={portal.isFullscreen}
        centered={portal.isCentered}
        size={portal.size}
        show={portal.isVisible}

        backdrop={
            !portal.isDismissible ? 'static' //backdrop visible; use backdropCls to hide
                : portal.isBackdropVisible //portal can be closed by clicking outside; if false there will be no backdrop!
        }

        backdropClassName={portal.backdropCls}
        aria-labelledby={portal.pid}
    >
        {
            portal.header ? portal.header
                :
                portal.title || portal.ico || portal.isClosable ?
                    <Modal.Header closeButton={portal.isClosable}>
                        <Modal.Title id={portal.pid}>
                            <span className='me-2'>{portal.ico}</span>{portal.title}
                        </Modal.Title>
                    </Modal.Header>
                    : ''
        }

        <Modal.Body className={portal.bodyCls}>
            {portal.children}
        </Modal.Body>

        {
            portal.footer || portal.description ?
                <Modal.Footer>
                    {portal.footer}
                    {
                        portal.description ?
                            <>
                                <div className='text-muted d-table mx-auto'>
                                    <span className='me-2'>{portal.ico}</span>{portal.description}
                                </div>
                            </>
                            : ''
                    }
                </Modal.Footer>
                : ''
        }
    </Modal>
}
