import {lf} from "../../DSYNR/Utils/debug";
import React from "react";
import {Container, Nav} from "react-bootstrap";
import {useSelector} from "react-redux";

export default function Pref() {

    lf('Pref');

    const sections = useSelector(state => state.app.pref.sections)

    let secData = []

    for (const sectionName in sections) {
        const secContent = sections[sectionName]
        secData.push(<Nav.Item>
            <Nav.Link as={"button"} className='bg-secondary text-white py-3'>
                <span className='fs-4 me-2'>{secContent.ico}</span>
                <span className='fs-5 fw-light'>{secContent.title}</span></Nav.Link>
        </Nav.Item>)
    }

    return <Container>
        <Nav fill variant="tabs" defaultActiveKey="/home">
            {React.Children.toArray(secData)}
        </Nav>
    </Container>
}
