import bg from '../_/img/bg-dashboard.jpg'
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import AppScreen from "./AppScreen";
import NotificationPanel from "../screens/notification/NotificationPanel";
import NewPortals from "../screens/NewPortals";
import Dashboard from "../screens/dashboard";
import {AppOptions} from "../_/options";

export default function AppMain() {

    let currentScreen = useSelector(state => state.app.now.currentScreen)
    let screen = AppOptions[currentScreen].screen || <AppScreen/>
    const appDataLoaded = useSelector(state => state.app.now.isDataLoaded)
    const isMainScreenFocused = useSelector(state => state.app.now.isMainScreenFocused)

    let mainCls = 'min-vh-100 d-flex align-items-center ' + (isMainScreenFocused ? '' : 'blur')
    return <>
        {appDataLoaded ? <AppHeader/> : ''}
        <main className={mainCls} style={{backgroundImage: `url(${bg})`}}>
            <div className='d-table mx-auto align-self-center'>
                {screen}
            </div>
        </main>
        {appDataLoaded ? <>
            <Dashboard/>
            <NotificationPanel/>
            <NewPortals/>
            <AppFooter/>
        </> : ''}
    </>
}
