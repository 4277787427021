export const AppInitialState = {
    now: {
        isDataLoaded: false,
        currentStatus: '',
        currentScreen: 'app',
        isMainScreenFocused: true,
        isDashboardVisible: false, //menu = dashboard
        isNotificationsVisible: false,
        isUpdating: false,
        netConnection: null,
        isOnline: true,
        isConnectionInfoVisible: false,
        isWorkOffline: false, //@todo implement
    },
    options: {},
    data: {
        account: {},
        pref: {},
    },
    search: {},
}
