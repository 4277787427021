import React from "react";
import {TiPointOfInterest} from "react-icons/all";
import AFormSummaryRow from "./AFormSummaryRow";

export default function AFormSummaryHint({text, ico = <TiPointOfInterest/>}) {
    return <AFormSummaryRow
        text={text}
        textSize='fs-5'
        ico={ico}
    />
}
