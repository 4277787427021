import {lf} from "../../DSYNR/Utils/debug";
import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import AFutureAppointmentsOverview from "../appointment/widgets/AFutureAppointmentsOverview";
import AOverview from "../../app/widgets/AOverview";
import AccountWidget from "../../app/widgets/AAccountOverview";
import AGoTo from "../../app/widgets/AGoTo";
import {AGreeting} from "../../app/AGreeting";
import {useDispatch, useSelector} from "react-redux";
import {TiArrowBack} from "react-icons/all";
import {hideMenuPanel} from "../../_/actions/ui";

export default function Dashboard() {

    lf('Dashboard');

    const dispatch = useDispatch()

    const isDashboardVisible = useSelector(state => state.app.now.isDashboardVisible)
    const isPanelVisibleCls = isDashboardVisible ? "isVisible" : ""

    const fname = useSelector(state => state.app.data.profile.fname)
    const dashboardTitle = useSelector(state => state.app.data.account.name)

    return <section id="dashboard" className={`bg-translucent bg-white bg-opacity-50 position-absolute top mt-5 py-5 min-vh-100 w-75 shadow-lg sidePanel ${isPanelVisibleCls}`}>

        <Button variant={"secondary"}
                onClick={() => {
                    dispatch(hideMenuPanel())
                }} className="text-white me-3 float-end">
            <TiArrowBack/>
        </Button>

        <Container>

            <Row>
                <Col>

                    <Container className='pt-5'>
                        <Row>
                            <Col>
                                <h1 className='display-1 fw-bold m-0'>Dashboard of {dashboardTitle}</h1>
                            </Col>
                        </Row>
                        <Row className="pt-5 pb-4">
                            <Col className='ps-0'>
                                <AOverview/>
                            </Col>
                            <Col>
                                <AGoTo/>
                            </Col>
                            <Col className='pe-0'>
                                <AccountWidget/>
                            </Col>
                        </Row>
                        <Row className="pt-5">
                            <Col>
                                <AFutureAppointmentsOverview/>
                            </Col>
                        </Row>
                    </Container>

                </Col>
            </Row>
        </Container>

        {/*<Button size='lg' variant={"dark"} className='d-table mx-auto' onClick={() => {*/}
        {/*    dispatch(changeScreen('dashboard'))*/}
        {/*    dispatch(hideMenuPanel())*/}
        {/*}}>Go to Dashboard</Button>*/}


    </section>
}
