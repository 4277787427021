import {lf} from "../../DSYNR/Utils/debug";

export default function Appointments() {

    lf('Screen :::: Appointments');

    return <>
        Appointments Screen
    </>
}
