import {Button, ButtonGroup, Carousel, CarouselItem} from "react-bootstrap";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {formWizardGotoSlide} from "./actions";

export default function _AFormWizard(form) {

    const dispatch = useDispatch()

    const wizardSlides = Object.keys(form.children)

    const wizard = useSelector(state => state.forms[form.fid].wizard)

    const isPrevBtnVisible = wizard.isPrevBtnVisible
    const isNextBtnVisible = wizard.isNextBtnVisible
    const activeIndex = wizard.activeIndex
    const maxIndex = wizard.maxIndex

    const gotoNextWizardStep = () => {
        if (activeIndex < maxIndex) {
            dispatch(formWizardGotoSlide(form, activeIndex + 1))
        }
    }

    const gotoPreviousWizardStep = () => {
        if (activeIndex > 0) {
            dispatch(formWizardGotoSlide(form, activeIndex - 1))
        }
    }

    return <>
        <Carousel
            activeIndex={activeIndex}
            interval={null}
            indicators={false}
            controls={false}
            keyboard={false}
            touch={false}
            wrap={false}
        >
            {wizardSlides.map(index => {
                return <CarouselItem key={index}>
                    {form.children[index]}
                </CarouselItem>
            })}
        </Carousel>

        <div className="mx-auto d-table mt-4">
            <ButtonGroup size="lg">

                {
                    activeIndex === 0 ? '' :
                        <>{
                            isPrevBtnVisible ? <Button
                                    disabled={form.hasErrors}
                                    className={wizard.prevBtnCls}
                                    variant={wizard.prevBtnVariant}
                                    onClick={gotoPreviousWizardStep}
                                >
                                    {wizard.prevBtnTxt}
                                </Button>
                                : ''
                        }</>
                }

                {
                    activeIndex === maxIndex - 1 ? '' :
                        <>{
                            isNextBtnVisible ? <Button
                                    disabled={form.hasErrors}
                                    className={wizard.nextBtnCls}
                                    variant={wizard.nextBtnVariant}
                                    onClick={gotoNextWizardStep}
                                >
                                    {wizard.nextBtnTxt}
                                </Button>
                                : ''
                        }</>
                }

            </ButtonGroup>
        </div>
    </>
}
