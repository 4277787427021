import React from "react";
import {useSelector} from "react-redux";
import {IoNotifications} from "react-icons/all";

export default function ANotificationBadge() {

    const notificationsCount = Object.keys(useSelector(state => state.app.data.notifications)).length
    const hasNotifications = notificationsCount > 0
    return <>
        <IoNotifications className="text-secondary mt-3 fs-2"/>
        {
            hasNotifications ?
                <div className="notificationBadge position-absolute fs-6 fw-bold bg-danger bg-gradient rounded-circle text-center border border-1 border-white mt-1 text-white">
                    {notificationsCount}
                </div>
                : ''
        }
    </>
}
