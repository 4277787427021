import {DsynrFormOptions} from "../options";
import {getForm, getFormControl, getFormControls, isFromControlTypeOption, setupFormControls} from "../utils";
import {formControlReset, formControlVirginise} from "../control/actions";
import {networkRequest} from "../../../Utils/network";
import {lf} from "../../../Utils/debug";
import {formWizardGotoSlide, formWizardINI} from "../wizard/actions";
import {updateObj} from "../../../Utils/obj";
import {isVisible} from "bootstrap/js/src/util";

//Prefer thunk actions, which are dispatchable from anywhere with ease.
// Define only pure (non-thunk) actions in here for exceptional cases.
export const FormActions = {
    enlistForm: form => {
        return {
            type: DsynrFormOptions.Form.Actions.enlistForm,
            payload: {
                form: form,
            }
        }
    },
    updateFormProp: (form, propName, propVal) => {
        return {
            type: DsynrFormOptions.Form.Actions.updateFormProp,
            payload: {
                form: form,
                prop: propName,
                val: propVal,
            }
        }
    }
}

export const formInitialise = form => {
    return function (dispatch) {

        // lf('form.............', form)

        form.controls = setupFormControls(form.fid, form.children)
        // lf('form.controls.............', form.controls)

        dispatch(FormActions.enlistForm(form))

        if (form.isWizard && (form.wizard === undefined || form.wizard.isActive === undefined)) {
            //merge any wizard props specified
            const wProps = updateObj(DsynrFormOptions.Form.Wizard.Props, form.wizard)
            const updatedFormProps = updateObj(form, {wizard: wProps})
            dispatch(formWizardINI(updatedFormProps))
        }
    }
}

export const formDataUpdate = (control, removeData = false) => {
    return function (dispatch) {
        const form = getForm(control.fid)
        let formData = form.data
        if (removeData) {
            delete formData[control.cid]
        } else {
            formData[control.cid] = control.value || control.checked
        }
        dispatch(FormActions.updateFormProp(form, 'data', formData))
        dispatch(FormActions.updateFormProp(form, 'hasData', Object.keys(formData).length > 0))
    }
}

export const formDataRecap = fid => {
    return function (dispatch) {
        const controls = getFormControls(fid)
        let formData = {}
        let propName = 'value'
        for (const cid in controls) {
            const control = controls[cid]
            if (!control.isVirgin) {
                if (isFromControlTypeOption(control)) {
                    propName = 'checked'
                }
                const cv = controls[cid][propName]
                if ((cv === undefined) && !controls[cid].isRequired) {
                    formData[cid] = '' //send '' instead of undefined!
                } else if (cv !== undefined) {
                    formData[cid] = cv
                }
            }
        }
        const form = getForm(fid)
        dispatch(FormActions.updateFormProp(form, 'data', formData))
        if (Object.keys(formData).length > 0) {
            dispatch(FormActions.updateFormProp(form, 'hasData', true))
        }
    }
}

export const formSubmit = form => {
    return function (dispatch) {
        if (form.submitAction && form.isSubmitActionOverride) {
            form.submitAction()
        } else {
            dispatch(formDataRecap(form.fid))
            dispatch(formSend(form))
            if (form.submitAction) {
                form.submitAction()
            }
        }
    }

}

export const formSend = form => {
    return async function (dispatch) {
        //submit only if there's any data
        //@todo disallow sending !isValid
        if (Object.keys(form.data).length > 0) {
            // lf('formSend...', form)
            let response = await networkRequest(form.submitURL, form.data, form.token, 'POST')
            dispatch(formSent(form, response))
        }
    }
}

const formSent = (form, response) => {
    return function (dispatch) {
        if (response.type === 'cors') {
            //Fetch succeeded, validate response
            if (response.status === 200) {
                dispatch(formSendingSuccessful(form, response))
            } else {
                //403
                dispatch(formSendingFailed(form, response))
            }
        } else {
            //Fetch failed, network issue
            dispatch(networkFailure(form, response))
        }
    }
}

export const formSendingSuccessful = form => {
    return async function (dispatch) {
        //@todo form.onSubmitSuccess
    }
}
export const formSendingFailed = form => {
    return async function (dispatch) {
        //@todo form.onSubmitError
    }
}

const networkFailure = (form, response) => {
    return async function (dispatch) {
        //@todo
    }
}

export const formReset = form => {
    return function (dispatch) {
        if (form.resetAction && form.isResetActionOverride) {
            form.resetAction()
        } else {
            if (form.isWizard) {
                dispatch(formWizardGotoSlide(form, 0))
            }

            // form = getForm(form.fid)

            for (const cid in form.controls) {
                const control = getFormControl(form.fid, cid)
                if(!control.isVirgin) {
                    dispatch(formControlReset(control, false))
                    dispatch(formControlVirginise(getFormControl(form.fid, cid)))
                }
            }
            dispatch(FormActions.updateFormProp(form, 'data', {}))
            dispatch(FormActions.updateFormProp(form, 'hasData', false))
            dispatch(FormActions.updateFormProp(form, 'errors', {}))
            dispatch(FormActions.updateFormProp(form, 'hasErrors', false))
            if (form.resetAction) {
                form.resetAction()
            }
        }
    }
}

export const formErrorsUpdate = (control, removeError = false) => {
    return function (dispatch) {
        const form = getForm(control.fid)
        let formErrors = form.errors
        if (formErrors[control.cid] && removeError) {
            delete formErrors[control.cid]
        } else {
            formErrors[control.cid] = control.errorTip
        }
        dispatch(FormActions.updateFormProp(form, 'errors', formErrors))
        dispatch(FormActions.updateFormProp(form, 'hasErrors', Object.keys(formErrors).length > 0))
    }
}



export const formToggleVisibility = (form, isVisible = true) => {
    return function (dispatch) {
        dispatch(FormActions.updateFormProp(form, 'isVisible', isVisible))
    }
}
