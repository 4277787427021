import {Bar as TheChart} from 'react-chartjs-2';
import {useDispatch, useSelector} from "react-redux";
import {AppOptions} from "../../../_/options";
import React from "react";
import AHint from "../../../app/AHint";
import {AGraphHeader} from "../../../templates/AGraphHeader";
import DsynrForm from "../../../DSYNR/DsynrForm";
import DsynrFormControl from "../../../DSYNR/DsynrForm/DsynrFormControl";
import {DsynrFormOptions} from "../../../DSYNR/DsynrForm/_/options";
import {getAppFormControlDefaults} from "../../../_/utils";

/**
 * @todo
 * @see https://www.chartjs.org/docs/latest/axes/styling.html
 * @returns {JSX.Element}
 * @constructor
 */
export default function AAppointmentsGraph() {

    const overview = useSelector(state => state.app.data.overview)
    const dispatch = useDispatch()

    const data = {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            {
                type: 'bubble',
                data: [3, 1, 7, 5, 6],
                label: 'Sessions',
                backgroundColor: 'rgb(0, 255, 255)',
                borderColor: 'rgb(255, 0, 255)',
                borderWidth: 1,
            },
            {
                type: 'line',
                data: [4, 1.5, 5, 5, 6],
                label: 'Hours',
                backgroundColor: 'transparent',
                borderColor: 'rgba(255, 0, 0, 0.1)',
                borderWidth: 5,
                fill: false,
            },
            {
                type: 'bar',
                data: [4, 4, 7, 6, 6, 0, 3],
                label: 'Appointments',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                borderColor: 'transparent',
                borderWidth: 1,
            },
        ],
    }

    const fid = 'appointmentPeriodForm'
    const formProps = {
        ...DsynrFormOptions.Form.Props,
        fid: fid,
        isProgressVisible: false,
        isSubmitBtnVisible: false,
        isResetBtnVisible: false,
        isWizard: false,
        isStyled: false,
    }
    const controlProps = {
        ...getAppFormControlDefaults(),
        cid: 'period',
        fid: fid,
        defaultValue: 'w',
        options: {
            d: 'Today',
            w: 'This Week',
            m: 'This Month',
            y: 'This Year',
        },
        hasSelfReset: false,
        isValidationVisible: false,
        cls: 'mt-2 d-inline-flex',
        highlightCls: 'mt-2 d-inline-flex',
    }

    const appointmentPeriodForm = <DsynrForm {...formProps}>
        <DsynrFormControl {...controlProps}/>
    </DsynrForm>
    //hint='How busy does your week look like'
    //@todo appointments vs sessions vs hours
    return <>
        <AGraphHeader title={<>Appointments{appointmentPeriodForm}</>}/>
        <TheChart
            data={data}
            options={
                {
                    redraw: true,
                    scales: {
                        x: {
                            grid: {
                                color: 'transparent',
                                // borderColor: 'grey',
                                // tickColor: 'grey'
                            }
                        },
                        y: {
                            grid: {
                                color: 'rgba(0, 0, 0, 0.1)',
                                // borderColor: 'grey',
                                // tickColor: 'grey'
                            }
                        }
                    }
                }
            }
        />
    </>;
}
