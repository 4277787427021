import {useDispatch, useSelector} from "react-redux";
import {BsCheckLg, BsExclamationLg, CgDanger, FaWifi, MdOutlineSyncProblem, RiSignalWifiOffFill, VscSync, VscSyncIgnored} from "react-icons/all";
import React from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {toggleNetworkInfo, updateNetworkStatus} from "../_/actions/network";

export default function NetworkBadge() {

    const dispatch = useDispatch()

    const isOnline = useSelector(state => state.app.now.isOnline)
    const isInfoVisible = useSelector(state => state.app.now.isConnectionInfoVisible)

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    connection.addEventListener('change', () => {
        dispatch(updateNetworkStatus(connection))
    });
    return <>
        <OverlayTrigger
            trigger='click'
            placement='bottom'
            show={isInfoVisible}
            overlay={
                <Popover
                    className='rounded-1 shadow-lg'
                    onClick={() => {
                        dispatch(toggleNetworkInfo(false))
                    }}>
                    <Popover.Body className='text-muted'>
                        {isOnline ?
                            <>
                                <h4 className='fs-6 fw-light text-center'>
                                    <span className='text-info fs-5 me-2'><VscSync/></span>
                                    You are Online
                                </h4>
                                <hr className='bg-opacity-75 bg-info'/>
                                <p className='text-info lead fs-6'>
                                    <BsCheckLg className='me-2'/>All Good!
                                </p>
                                <div className='font-monospace'>
                                    <p className='mb-4'><BsCheckLg className='text-info me-2'/>Your work is being synced with the server.</p>
                                    <p><BsCheckLg className='text-info me-2'/>You will be notified of new updates automatically.</p>
                                </div>
                            </>
                            :
                            <>
                                <h4 className='fs-6 fw-light text-center'>
                                    <span className='text-danger fs-5 me-2'><VscSyncIgnored/></span>
                                    You are Offline
                                </h4>
                                <hr className='bg-opacity-75 bg-danger'/>
                                <p className='lead fs-6 text-danger'>
                                    <BsExclamationLg className='me-1'/> Important Information
                                </p>
                                <div className='font-monospace'>
                                    <p className='mb-4'><BsCheckLg className='text-secondary me-2'/>You can continue to work normally, however, features which require internet connection will not work.</p>
                                    <p className='mb-4'><MdOutlineSyncProblem className='text-danger fs-4 me-2'/>Your work will be temporarily saved to this computer. Once the internet connection is restored, it will
                                        be synced to the server automatically.</p>
                                    <p className='text-danger fw-bolder'><CgDanger className='fs-5 me-2'/>If you clear this browsers' data, any work which was not synced to the server will be lost.</p>
                                </div>
                            </>
                        }
                    </Popover.Body>
                </Popover>
            }
        >
            <div
                className='float-end position-relative p-4 fs-3 hand'
                onClick={() => {
                    dispatch(toggleNetworkInfo(!isInfoVisible))
                }}
            >
                {
                    isOnline ? <FaWifi className="text-dark"/>
                        : <RiSignalWifiOffFill className="text-danger"/>
                }</div>
        </OverlayTrigger>

    </>
}
