import {FormCheck} from "react-bootstrap";
import {formControlBlur, formControlChange, formControlFocus, formControlSetDefaultVal, formControlSetVal} from "./actions";
import React from "react";
import {useDispatch, useSelector} from "react-redux";

export default function _AFormControlTypeOption({conditionalAttributes, ...control}) {

    const dispatch = useDispatch()
    control = useSelector(state => state.forms[control.fid].controls[control.cid])

    return <>
        <FormCheck
            required={control.isRequired}
            isValid={control.isValid}
            isInvalid={control.isInvalid}
            checked={control.checked}
            inline={control.isLabelInline}
            size={control.size}
            autoFocus={control.isFocused}

            readOnly={control.isReadOnly}

            disabled={control.isDisabled}

            onFocus={() => {
                dispatch(formControlFocus(control))
            }}
            onBlur={() => {
                dispatch(formControlBlur(control))
            }}
            onChange={(e) => {
                dispatch(formControlChange(control, e.currentTarget.checked))
            }}

            {...conditionalAttributes}
        />
    </>
}
