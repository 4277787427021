import {PolarArea as TheChart} from 'react-chartjs-2';
import {useSelector} from "react-redux";
import {AppOptions} from "../../_/options";
import React from "react";
import AHint from "../AHint";
import {AGraphHeader} from "../../templates/AGraphHeader";

/**
 * @see https://www.chartjs.org/docs/latest/charts/polar.html
 * @returns {JSX.Element}
 * @constructor
 */
export default function ABusinessOverviewGraph() {

    const overview = useSelector(state => state.app.data.overview)
    const companyName = useSelector(state => state.app.data.account.name)

    let labels = []
    let datum = []

    Object.keys(overview).map((item, i) => {
        if (AppOptions[item] && item) {

            if (AppOptions[item].isNewable) {
                labels.push(AppOptions[item].screenTitle)
                datum.push(overview[item])
            }
        }
    })

    const data = {
        labels: labels,
        datasets: [
            {
                // label: '# of Votes',
                data: datum,
                backgroundColor: [
                    'rgba(0, 155, 255, 0.15)',
                    'rgba(0, 0, 0, 0.05)',
                    'rgba(0, 50, 0, 0.05)',
                    'rgba(50, 0, 50, 0.05)',
                    'rgba(15, 255, 150, 0.15)',
                    'rgba(10, 0, 150, 0.25)',
                ],
                // borderColor: 'rgba(0, 0, 0, 0.1)',
                // borderWidth: 0.5,
            },
        ],
    };

    const options = {
        scale: {
            ticks: {beginAtZero: true},
        },
    };

    //@todo enhance
    return <>
        <AGraphHeader title='Your Business at a Glance' hint={companyName}/>
        <TheChart data={data} options={options}/>
    </>;
}
