import {formControlBlur, formControlChange, formControlFocus} from "./actions";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {setHours, setMinutes} from "date-fns";

//@todo onfocus/blur not working
export default function _AFormControlTypeDateTime({...control}) {

    const dispatch = useDispatch()
    control = useSelector(state => state.forms[control.fid].controls[control.cid])

    const dateTime = Date.parse(control.value) //@todo provision for displaying friendly time
//https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
    return <DatePicker
        inline
        // utcOffset={-4}
        selected={dateTime}
        onFocus={() => {
            dispatch(formControlFocus(control))
        }}
        onBlur={() => {
            dispatch(formControlBlur(control))
        }}
        openToDate={dateTime}
        onChange={(date) => {
            dispatch(formControlChange(control, date.toString()))
        }}
        dateFormatCalendar='LLLL'
        allowSameDay={false}
        placeholderText="Select a date other than today or yesterday"
        showTimeSelect={control.hasTime}
        excludeTimes={[
            setHours(setMinutes(new Date(), 0), 17),
            setHours(setMinutes(new Date(), 30), 18),
            setHours(setMinutes(new Date(), 30), 19),
            setHours(setMinutes(new Date(), 30), 17),
        ]}
    />
}
