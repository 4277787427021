import {lf} from "../../DSYNR/Utils/debug";
import {Button, Col, Container, Row} from "react-bootstrap";
import {getState} from "../../DSYNR/Utils/react";
import {addNewItem} from "../../_/actions";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import ServiceFormSummary from "./ServiceFormSummary";
import DsynrForm from "../../DSYNR/DsynrForm";
import AFormSectionHeading from "../../templates/AFormSectionHeading";
import DsynrFormControl from "../../DSYNR/DsynrForm/DsynrFormControl";
import AFormSelectUser from "../user/widgets/AFormSelectUser";
import UserFormSummary from "../user/UserFormSummary";
import AFormWithSummary from "../../templates/AFormWithSummary";
import {DsynrFormOptions} from "../../DSYNR/DsynrForm/_/options";
import {getAppFormControlDefaults} from "../../_/utils";
import AHint from "../../app/AHint";
import {updateAppStatus} from "../../_/actions/app";

export default function NewServiceForm() {
    lf('NewServiceForm')

    const dispatch = useDispatch()

    const APIS = useSelector(state => state.app.data.account.apis)

    const token = useSelector(state => state.app.data.account.token)
    const services = useSelector(state => state.app.options.services)

    const formID = 'service'
    const formPostURL = APIS.add

    let serviceOptions = {'0': 'Select a service'}
    for (const sid in services) {
        const srvc = services[sid]
        serviceOptions[sid] = srvc.name
    }
    const defaultService = 0

    let durationOptions = {}



    const formSubmissionSuccessful = () => {
        lf('formSubmissionSuccessful', getState('forms'))
        // alert('formSubmissionSuccessful')
    }

    const formSubmissionError = () => {
        lf('formSubmissionError', getState('forms'))
        dispatch(updateAppStatus('onSubmitError...'))
    }

    const fid = 'newServiceForm'

    const formProps = {
        ...DsynrFormOptions.Form.Props,
        fid: fid,
        token: token,
        submitURL: formPostURL,
        isSubmitBtnVisibleDuringWizard: false,
        resetBtnTxt: 'Cancel & Restart',
        progressBarRootCls: 'bg-white rounded my-3 overflow-hidden',
        onSubmitSuccess: formSubmissionSuccessful,
        onSubmitError: formSubmissionError,
        // isWizard: false,
        // isVisible: false,
        submitBtnTxt: 'Save',
    }

    const email = {
        ...getAppFormControlDefaults(),
        cid: 'email',
        fid: fid,
        isRequired: true,
        type: DsynrFormOptions.Form.Control.Types.email,
        placeholder: 'Enter email here...',
        extraInfo: <AHint msg='The email address can be used as the username'/>,
        extraInfoCls: 'm-1',
    }

    return <AFormWithSummary
        {...formProps}
        form={
            <DsynrForm {...formProps}>

                <section className='p-5'>
                    <AFormSectionHeading heading="Service Details"/>
                    <DsynrFormControl {...email}/>
                </section>


            </DsynrForm>
        }
    />
}
