import {lf} from "../../DSYNR/Utils/debug";
import React from "react";
import {Button, Card, Image, ListGroup} from "react-bootstrap";
import {BsThreeDots} from "react-icons/all";
import {useSelector} from "react-redux";
import ASpinner from "../ASpinner";
import ADashboardWidget from "../../templates/ADashboardWidget";
import {AppOptions} from "../../_/options";

export default function AAccountOverview() {
    lf('AAccountOverview')

    const icoAccount = useSelector(state => state.app.data.account.ico)
    const account = useSelector(state => state.app.data.account)
    const profile = useSelector(state => state.app.data.profile)
    const dsynr = AppOptions.dsynr

    const summary = <>
        <Card.Title>{account.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{account.timezone}</Card.Subtitle>
        <Card.Text>
            {account.companyDetails}
        </Card.Text>
        <Button variant={"light"} className="fs-4"><BsThreeDots/></Button>
    </>

    return <ADashboardWidget
        ico={icoAccount}
        title='Your Account'
        body={
            <>
                <Image src={profile.img || dsynr.logo}
                       roundedCircle width="50" height="50"/>

                <Card.Title>{profile.title}. {profile.fname} {profile.lname}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{profile.role}</Card.Subtitle>
                Registered since {profile.registered}
                <hr/>
                {summary || <ASpinner/>}
            </>
        }
    />
}
