import {DsynrFormOptions} from "../../../DSYNR/DsynrForm/_/options";
import React from "react";
import DsynrFormControl from "../../../DSYNR/DsynrForm/DsynrFormControl";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import AHint from "../../../app/AHint";
import AGenericWidget from "../../../templates/AGenericWidget";
import {getAppFormControlDefaults} from "../../../_/utils";
import {addNewItem} from "../../../_/actions";
import {AppFormDefaults} from "../../../_/options";
import {lf} from "../../../DSYNR/Utils/debug";
import {getFormControl, updateFormControlProp} from "../../../DSYNR/DsynrForm/_/utils";
import {ControlActions} from "../../../DSYNR/DsynrForm/_/control/actions";

export default function AFormService({fid, cid = 'service', allowAddNew = true, ...props}) {

    const services = useSelector(state => state.app.options.service)
    const dispatch = useDispatch()

    // const defaultUser = useSelector(state => state.app.pref.defaultUser)

    const defaultService = 0
    let serviceOptions = {'0': 'Select a service'}
    let packageOptions = {}
    for (const key in services) {
        let service = services[key]
        serviceOptions[key] = service.name
    }

    const packageCID = cid + 'Package'
    const serviceProps = {
        ...getAppFormControlDefaults(),
        cid: cid,
        fid: fid,
        defaultValue: defaultService,
        options: serviceOptions,
        label: props.label || 'Select a service',
        isRequired: props.isRequired || false,
        onChange: (v) => {
            if (v == 0) {//@todo remove dependency on hardCoded 0 and match it to defaultService
                dispatch(ControlActions.updateControlProp(getFormControl(fid, packageCID), 'isVisible', false))
            } else {
                packageOptions = {}
                dispatch(ControlActions.updateControlProp(getFormControl(fid, packageCID), 'isVisible', true))
                let service = services[v]
                if (service.options) { //@todo update onChange
                    for (const price in service.options.packages) {
                        packageOptions[price] = Object.values(service.options.packages[price])[0]
                    }
                    dispatch(ControlActions.updateControlProp(getFormControl(fid, packageCID), 'options', packageOptions))
                }
            }
        }
    }

    const packageProps = {
        ...getAppFormControlDefaults(),
        cid: packageCID, //used in AFormService to dispatch isVisible
        fid: fid,
        // defaultValue: defaultService,
        options: packageOptions,
        label: 'Select session duration',
        isRequired: props.isRequired || false,
        isVisible: false,
    }

    return <AGenericWidget
        body={
            <>
                <DsynrFormControl {...serviceProps}/>
                <DsynrFormControl {...packageProps}/>
            </>
        }
        footer={
            <>
                <Button variant={"link"}
                        onClick={() => {
                            dispatch(addNewItem('service'))
                        }}>
                    Add a new Service
                </Button>
            </>
        }
    />
}
