import React from "react";
import {Image} from "react-bootstrap";
import AFormSummaryTitle from "./AFormSummaryTitle";
import {useSelector} from "react-redux";
import {AppOptions} from "../_/options";

export default function AFormSummaryHeader({title, text, titleCls = 'text-dark'}) {

    const dsynr = AppOptions.dsynr
    const account = useSelector(state => state.app.data.account)

    return <>
        <Image src={account.logo || dsynr.logo} roundedCircle width="60" height="60" className="position-absolute left bg-darkGradient" style={{left: '50%', marginLeft: '-30px', top: '-25px'}}/>

        <div className='mt-3 mb-4'>

            <AFormSummaryTitle text={title} txtCls={titleCls}/>
            <div className='opacity-50 mb-4'><hr/></div>
            {text}
        </div>
    </>
}
