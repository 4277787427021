import React from "react";
import DsynrFormControl from "../../../DSYNR/DsynrForm/DsynrFormControl";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";
import AHint from "../../../app/AHint";
import AGenericWidget from "../../../templates/AGenericWidget";
import {DsynrFormOptions} from "../../../DSYNR/DsynrForm/_/options";
import {getAppFormControlDefaults} from "../../../_/utils";
import {addNewItem} from "../../../_/actions";
import {AppFormDefaults} from "../../../_/options";

export default function AFormSelectUser({fid, cid = 'user', allowAddNew = true, ...props}) {

    const users = useSelector(state => state.app.data.users)
    const dispatch = useDispatch()

    // const defaultUser = useSelector(state => state.app.pref.defaultUser)
    const defaultUser = 0

    let options = {'0': 'Select a person'}
    for (const uid in users) {
        const usr = users[uid]
        const u = `${usr.fname} ${usr.email}`
        // const u = `${usr.fname} ${usr.lname} ${usr.email} ${usr.role}`
        options[uid] = u
    }

    const controlProps = {
        ...getAppFormControlDefaults(),
        cid: cid,
        fid: fid,
        defaultValue: defaultUser,
        options: options,
        label: props.label || 'Select a person',
        isRequired: props.isRequired || false,
    }

    return <AGenericWidget
        body={<DsynrFormControl {...controlProps}/>}
        footer={allowAddNew ?
            <>
                <Button variant={"link"}
                        onClick={() => {
                            dispatch(addNewItem('user'))
                        }}>
                    Add a new Person
                </Button>
                <br/>
                <AHint msg='You can add Patients, Referrers, Doctors and more.'/>
            </>
            : undefined
        }
    />
}
