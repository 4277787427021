import {lf} from "../../DSYNR/Utils/debug";
import {Col, Container, Row} from "react-bootstrap";
import {STORE} from "../../_/options";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getState} from "../../DSYNR/Utils/react";

export default function NewOrder() {
    lf('NewOrder')

    const dispatch = useDispatch()

    const APIS = useSelector(state => state.app.account.apis)

    const token = useSelector(state => state.app.account.token)
    const vouchers = useSelector(state => state.app.vouchers)
    const gcs = useSelector(state => state.app.gcs)
    const paymentModes = useSelector(state => state.app.order.options.paymentMode)

    const formID = 'appointment'
    const formPostURL = APIS.add

    return <>new order</>
}
