import React from "react";
import {useDispatch, useSelector} from "react-redux";
import AppMain from "./AppMain";
import {getCookies} from "../DSYNR/Utils/storage";
import AppScreen from "./AppScreen";
import ASpinner from "./ASpinner";
import {APIS} from "../_/options";
import {hydrateApp, populateApp, send2Login} from "../_/actions/app";

export default function App() {

    const dispatch = useDispatch()

    const cookies = getCookies()
    if (!cookies) {
        //cookies not found, login..
        dispatch(send2Login())
    }

    // const domain = useSelector(state => state.app.data.account.domain)
    const isLogged = useSelector(state => state.app.data.account.isLogged)
    const token = useSelector(state => state.app.data.account.token)

    const updateFrequency = useSelector(state => state.app.data.pref.updateFrequency) * 60000

    const appDataLoaded = useSelector(state => state.app.now.isDataLoaded)
    const isUpdating = useSelector(state => state.app.now.isUpdating)

    //@todo
    // const currentStatus = useSelector(state => state.app.now.currentStatus)
    // let app = <div className='my-5'><ASpinner message={currentStatus}/></div>

    let app = ''



    if (!isLogged) {
        dispatch(hydrateApp(cookies))
    } else if (isLogged && !appDataLoaded) {
        dispatch(populateApp(token, updateFrequency, isUpdating))
    } else {
        app = <AppMain/>
    }

    return <>{app}</>
}
