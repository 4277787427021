import {lf} from "../../DSYNR/Utils/debug";
import {Button, Col, Container, Row} from "react-bootstrap";
import {ImForward} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import NotificationSlot from "./NotificationSlot";
import React from "react";
import AccountWidget from "../../app/widgets/AAccountOverview";
import AGoTo from "../../app/widgets/AGoTo";
import {changeScreen, hideMenuPanel, hideNotificationPanel} from "../../_/actions/ui";

export default function NotificationPanel() {
    lf('NotificationPanel');

    const dispatch = useDispatch()

    const isPanelVisible = useSelector(state => state.app.now.isNotificationsVisible)
    const isPanelVisibleCls = isPanelVisible ? "isVisible" : ""


    const panelStyles = 'bg-translucent position-absolute top mt-5 pt-5 h-100 shadow-lg sidePanel ' + isPanelVisibleCls

    return <section id="notificationPanel" className={panelStyles}>

        <Button variant={"secondary"}
                onClick={() => {
                    dispatch(hideNotificationPanel())
                }} className="text-white me-3 float-end">
            <ImForward/>
        </Button>

        <NotificationSlot/>

        {/*<Container className='border-top p-5'>*/}

        {/*    <Row>*/}
        {/*        <Col>*/}
        {/*            <AGoTo/>*/}
        {/*        </Col>*/}
        {/*    </Row>*/}
        {/*</Container>*/}

        {/*<Button size='lg' variant={"dark"} className='d-table mx-auto' onClick={() => {*/}
        {/*    dispatch(changeScreen('dashboard'))*/}
        {/*    dispatch(hideMenuPanel())*/}
        {/*}}>Go to Dashboard</Button>*/}


    </section>
}
