import {
    BsFillPeopleFill,
    BsInfoCircleFill,
    CgShapeHexagon,
    FaCcStripe,
    FaCertificate,
    FaCog,
    FaDollarSign,
    FaFileInvoiceDollar,
    GiFluffyTrefoil,
    GiHealing,
    GiStethoscope,
    GrDocumentUser,
    GrYoga,
    IoDocument,
    RiHealthBookFill,
    RiMapPinUserFill,
    RiUserReceivedFill,
    RiUserSmileLine,
    SiInfluxdb,
    SiMicrosoftexcel
} from "react-icons/all";
import NewAppointmentForm from "../screens/appointment/NewAppointmentForm";
import NewSession from "../screens/session/NewSession";
import NewServiceForm from "../screens/service/NewServiceForm";
import NewGiftCertificate from "../screens/gc/NewGiftCertificate";
import NewVoucher from "../screens/voucher/NewVoucher";
import NewOrder from "../screens/order/NewOrder";
import NewUserForm from "../screens/user/NewUserForm";
import Dashboard from "../screens/dashboard";
import Appointments from "../screens/appointment";
import Account from "../screens/account";
import Pref from "../screens/pref";
import NewReport from "../screens/report/NewReport";
import NewAssessment from "../screens/assessment/NewAssessment";
import NewHistory from "../screens/history/NewHistory";
import NewInvoice from "../screens/invoice/NewInvoice";
import NewAddress from "../screens/address/NewAddress";
import Home from "../screens/home";
import {DsynrFormOptions} from "../DSYNR/DsynrForm/_/options";
import {Pencil} from "react-bootstrap-icons";
import React from "react";

export const AppOptions = {
    home: {
        screenTitle: 'Home',
        screen: <Home/>,
        ico: <SiInfluxdb/>,
        isVisible: false,
        hasPage: true,
    },

    account: {
        screenTitle: 'Your Account',
        screen: <Account/>,
        ico: <GiFluffyTrefoil/>,
        isVisible: false,
        hasPage: true,
        apis: undefined,
        sections: {
            profile: {
                title: 'Your Profile',
                ico: <RiUserSmileLine/>,
            },
            company: {
                title: 'Company',
                ico: <CgShapeHexagon/>,
            },
            plan: {
                title: 'Plan',
                ico: <CgShapeHexagon/>,
            },
            payment: {
                title: 'Payment',
                ico: <FaCcStripe/>,
            },
        },
    },

    overview: {
        screenTitle: 'Overview',
        ico: <SiInfluxdb/>,
        summary: {},
    },

    dashboard: {
        screenTitle: 'Dashboard',
        screen: <Dashboard/>,
        ico: <SiInfluxdb/>,
        isVisible: false,
        // hasPage: true,
    },

    appointment: {
        screenTitle: 'Appointments',
        screen: <Appointments/>,
        ico: <RiHealthBookFill/>,
        new: <NewAppointmentForm/>,
        isVisible: false,
        isNewable: true,
        isNewVisible: false,
        isDirectlyViewable: true,
        futureAppointments: {},
        options: {},
    },

    session: {
        screenTitle: 'Sessions',
        screen: 'todo....',
        ico: <GrYoga/>,
        isVisible: false,
        new: <NewSession/>,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    history: {
        screenTitle: 'Histories',
        ico: <RiUserReceivedFill/>,
        new: <NewHistory/>,
        isNewVisible: false,
        options: {},
    },

    assessment: {
        screenTitle: 'Assessments',
        ico: <GiStethoscope/>,
        new: <NewAssessment/>,
        isNewVisible: false,
        options: {},
    },

    report: {
        screenTitle: 'Reports',
        ico: <GrDocumentUser/>,
        new: <NewReport/>,
        isNewVisible: false,
        options: {},
    },

    service: {
        screenTitle: 'Services',
        screen: 'todo....',
        ico: <GiHealing/>,
        new: <NewServiceForm/>,
        isVisible: false,
        isNewable: true,
        isNewVisible: false,
        isDirectlyViewable: true,
    },
    //used to store corresponding service options, populated when services load. used to serve as datasrc when service changes in form select
    serviceo: undefined,

    gc: {
        screenTitle: 'Gift Certificates',
        screen: 'todo....',
        ico: <FaCertificate/>,
        new: <NewGiftCertificate/>,
        isVisible: false,
        isNewable: true,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    voucher: {
        screenTitle: 'Vouchers',
        screen: 'todo....',
        ico: <IoDocument/>,
        new: <NewVoucher/>,
        isVisible: false,
        isNewable: true,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    order: {
        screenTitle: 'Orders',
        screen: 'todo....',
        ico: <FaDollarSign/>,
        new: <NewOrder/>,
        isVisible: false,
        isNewable: true,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    invoice: {
        screenTitle: 'Invoices',
        ico: <FaFileInvoiceDollar/>,
        new: <NewInvoice/>,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },

    user: {
        screenTitle: 'People',
        screen: 'todo....',
        ico: <BsFillPeopleFill/>,
        new: <NewUserForm/>,
        isVisible: false,
        isNewable: true,
        isNewVisible: false,
        isDirectlyViewable: true,
        options: {},
    },
    address: {
        screenTitle: 'Addresses',
        ico: <RiMapPinUserFill/>,
        new: <NewAddress/>,
        options: {},
    },
    insights: {
        screenTitle: 'Business Insights',
        screen: 'todo....',
        ico: <BsInfoCircleFill/>,
        isVisible: false,
        isPanelVisible: false,
        hasPage: true,
    },
    notifications: [],
    notification: {
        screenTitle: 'Notifications',
        screen: 'todo....',
        ico: <BsInfoCircleFill/>,
        isVisible: false,
        isPanelVisible: false,
        hasPage: true,
    },

    search: {},

    app: {
        currentStatus: '',
        isDataLoaded: false,
        isWorkOffline: false,
    },
    ie: {
        screenTitle: 'Import / Export Data',
        screen: 'todo....',
        ico: <SiMicrosoftexcel/>,
        isVisible: false,
        hasPage: true,
    },

    pref: {
        screenTitle: 'Preferences',
        screen: <Pref/>,
        ico: <FaCog/>,
        isVisible: false,
        hasPage: true,
        updateFrequency: 10, //min
        sections: {
            app: {
                title: 'App',
                ico: <CgShapeHexagon/>,
            },
            session: {
                title: 'Session',
                ico: <CgShapeHexagon/>,
            },
            appointment: {
                title: 'Appointment',
                ico: <CgShapeHexagon/>,
            },
        },
    },

    network: {
        connection: null,
        isOnline: true,
        isInfoVisible: false,
    },
    currentScreen: 'app',
    isMainScreenFocused: true,
    isDashboardVisible: false, //menu = dashboard
    updates: {
        isUpdating: false,
    },
    dsynr: {
        logo: 'https://ajain.ca/Dsynr-logo.svg',
        url: 'https://dsynr.com',
    },
}

export const AppFormDefaults = {
    ControlProps: {
        type: DsynrFormOptions.Form.Control.Types.select,
        selfResetTxt: <Pencil/>,
        selfResetCls: undefined,
        selfResetVariant: 'dark',

        options: {},//select only

        cls: 'p-3',

        highlightCls: 'p-3 rounded-3 bg-warning shadow',

        labelCls: 'text-muted',
        isLabelFloating: true,
        isLabelConjoined: false,
        isLabelVisible: true, //doesnt hide label when isLabelFloating
        isLabelInline: true, //applies to checkboxes and radios only

        placeholderCls: 'text-muted', //applies only when isLabelFloating (placeholder is shown along _AFormControlExtraInfo instead of inline in control where the label is showing)

        extraInfoCls: 'text-muted opacity-75',

        errorTip: 'Eh!',
        errorCls: 'p-3 mx-5 rounded-3 shadow bg-warning bg-opacity-25 border border-warning', //replaces the control container cls
    }
}

/**
 * The api values will be updated once the account domain is available and jwt auth token verified
 * @see setAccApis
 */
export let APIS = {
    'ping': undefined,
    'profile': undefined,
    'pref': undefined,

    'sup': undefined,
    'ini': undefined,
    'fd': undefined,

    'search': undefined,

    'add': undefined,

    'users': undefined,
    'services': undefined,
    'orders': undefined,
    'invoices': undefined,
    'appointments': undefined,
    'historys': undefined,
    'assessments': undefined,
    'reports': undefined,
    'sessions': undefined,
    'addresses': undefined,
    'vouchers': undefined,
    'gcs': undefined,
}
