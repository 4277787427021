import AGenericWidget from "../../../templates/AGenericWidget";
import DsynrFormControl from "../../../DSYNR/DsynrForm/DsynrFormControl";
import {Button} from "react-bootstrap";
import React from "react";
import {DsynrFormOptions} from "../../../DSYNR/DsynrForm/_/options";
import AHint from "../../../app/AHint";
import {getAppFormControlDefaults} from "../../../_/utils";

export default function AFormSelectDateTime({fid}) {

    const dateTimeProps = {
        ...getAppFormControlDefaults(),
        cid: 'dateTime',
        fid: fid,
        type: DsynrFormOptions.Form.Control.Types.dateTime,
        extraInfo: 'Select the date and time',
        isRequired: true,
    }

    return <AGenericWidget
        body={
            <div className='text-center mt-3'>
                <DsynrFormControl {...dateTimeProps}/>
            </div>
        }
        footer={
            <>
                <Button variant={"link"}
                        onClick={() => {
                            //open add new service form
                        }}>
                    This Appointment is for now
                </Button>
                <br/>
                <AHint msg='This will begin the therapy session once you confirm the appointment in the next step.'/>
            </>
        }
    />
}
