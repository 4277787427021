import {updateObj} from "../../Utils/obj";
import {getState} from "../../Utils/react";
import {DsynrFormOptions} from "./options";
import React from "react";

const controlTypes = DsynrFormOptions.Form.Control.Types

export const hasProps = el => {
    return Object.keys(el.props).length > 0
}

export const hasChildrenReact = props => {
    return React.Children.count(props.children) > 1
}

export const hasChildren = o => {
    return Object.keys(o.props.children).length > 0
}

export const isReactComp = c => {
    return typeof c.type === "object" //DOM element, if string
}

export const isFromControlTypeSelect = control => {
    return control.type === controlTypes.select
}

export const isFromControlTypeDateTime = control => {
    return control.type === controlTypes.dateTime
}

export const isFromControlTypeOption = control => {
    return control.type === controlTypes.checkbox
        || control.type === controlTypes.radio
        || control.type === controlTypes.switch
}

export const isFromControlTypeText = control => {
    return control.type === controlTypes.text
        || control.type === controlTypes.textarea
        || control.type === controlTypes.email
        || control.type === controlTypes.number
        || control.type === controlTypes.password
}

export const isFromControlTypeEmail = control => {
    return control.type === controlTypes.email
}

export const setupFormControls = (fid, children) => {

    // lf('setupFormControls.....', fid, children)
    let sectionControls = {}
    if (children !== undefined) {
        if (Array.isArray(children)) {
            children.map(child => {
                // lf('mapping...', child)
                sectionControls = {...sectionControls, ...setupFormControls(fid, child)}
            })
            // lf('isArray', sectionControls)
        } else if (typeof children === "object" && isFormControl(children)) {
            // lf(children, children.children, children.props.name, children.props, '-<<<<<<')
            sectionControls = {
                [children.props.cid]: children.props
            }
            // let sectionControl = sectionControls[children.props.cid]
            // sectionControl = updateObj(sectionControl, {fid: fid})
            // sectionControls = updateObj(sectionControls, {[children.props.cid]: sectionControl})
            // lf('isFormControl', sectionControls)
        } else if (typeof children !== "string" && hasProps(children)) {
            // lf('RECURSIVE >>> >> >', children, children.props)
            sectionControls = setupFormControls(fid, children.props.children)
        }
    }
    // lf('sectionControls :::: ', sectionControls)

    return sectionControls
}

export const isFormControl = control => {
    // lf(control.props,control.props.children,'.................control')
    return DsynrFormOptions.Form.Control.Types[control.props.type] !== undefined
}

export const getForm = (fid) => {
    // lf('getForm', fid, getState('forms', fid))
    return getState('forms', fid) || false
}
export const getFormControls = (fid) => {
    // lf('getFormControls', fid, getForm(fid).controls)
    return getForm(fid).controls || false
}
export const getFormControl = (fid, cid) => {
    // lf('getFormControl', fid, cid, getFormControls(fid)[cid])
    return getFormControls(fid)[cid] || false
}

export const updateForm = (form, propName, propVal) => {
    return updateObj(form, {[propName]: propVal})
}

export const updateFormControls = (form, control) => {
    // lf('updateFormControls========',control)
    // lf('updateFormControls>>>>>>',o)
    return updateObj(form.controls, {[control.cid]: control})
}

export const updateFormControlProp = (control, propName, propVal) => {
    // lf('updateFormControlProp..........',o)
    return updateObj(control, {[propName]: propVal})
}

export const updateFormWizard = (form, wizard) => {
    return updateObj(form.wizard, wizard)
}

export const updateFormWizardProp = (wizard, propName, propVal) => {
    return updateObj(wizard, {[propName]: propVal})
}

