import {lf} from "../../DSYNR/Utils/debug";
import {unformatToken, updateAppDataProp, updateAppNowProp} from "../utils";
import {clearCookies, getCookie} from "../../DSYNR/Utils/storage";
import {update} from "../actions";
import {APIS} from "../options";
import {fetchAPI, apiFetched_ini, setAccApis, apiFetched_options, apiFetched_records} from "./api";
import {enableBackgroundServerUpdates} from "./network";

require('dotenv').config()


export const signOut = () => {
    lf('signOut')
    return function (dispatch) {
        clearCookies()
        dispatch(send2Login())
    }
}
export const send2Login = () => {
    lf('send2Login')
    return function (dispatch) {
        window.location.href = `https://${
            process.env.NODE_ENV === 'development' ?
                process.env.REACT_APP_DEV_ACC : process.env.REACT_APP_PROD_ACC
        }`
    }
}
export const updateAppStatus = status => {
    return function (dispatch) {
        dispatch(updateAppNow('currentStatus', status))
    }
}
export const updateAppData = (propName, propVal) => {
    return function (dispatch) {
        dispatch(update('data', updateAppDataProp(propName, propVal)))
    }
}
export const updateAppNow = (propName, propVal) => {
    lf('updateAppNow....@', propName, propVal)
    return function (dispatch) {
        dispatch(update('now', updateAppNowProp(propName, propVal)))
    }
}
export const reAuthenticate = (from) => {
    lf('reAuthenticate', from)
    return function (dispatch) {
        dispatch(signOut())
    }
}
export const setDomain = () => {
    lf('setDomain')
    return function (dispatch) {
        const domain = getCookie('dom')
        if (!domain) {
            dispatch(updateAppStatus('Domain validation failed'))
            dispatch(reAuthenticate('setDomain'))
            return false
        }
        dispatch(updateAppData('account', {domain: domain}))
        return domain
    }
}
export const populateApp = (token, updateFrequency, isUpdating) => {
    lf('populateApp', token, updateFrequency, isUpdating)
    return function (dispatch) {

        const onSubmitError = () => {
            lf('onSubmitError.........................')
            dispatch(updateAppStatus('onSubmitError...'))
        }

        dispatch(updateAppStatus('Loading core data...'))
        dispatch(fetchAPI(APIS.ini, token, (response) => {

            dispatch(apiFetched_ini(response.data))

            dispatch(updateAppStatus('Loading datasets...'))
            dispatch(fetchAPI(APIS.fd, token, (response) => {
                dispatch(apiFetched_options(response.data))

                dispatch(updateAppStatus('Loading user data...'))
                dispatch(fetchAPI(APIS.users, token, (response) => {


                    dispatch(updateAppNow('isDataLoaded', true))
                    dispatch(updateAppStatus('App Data Loaded. Ready for you!'))

                    let data = response.data
                    data.recordType = 'user'
                    dispatch(apiFetched_records(data))

                    // dispatch(enableBackgroundServerUpdates(APIS.sup, updateFrequency, isUpdating, token)) //@todo fix iteration
                }, onSubmitError))
            }, onSubmitError))
        }, onSubmitError))
    }
}
export const hydrateApp = (cookies) => {
    lf('hydrateApp', cookies)

    return function (dispatch) {

        const domain = dispatch(setDomain())

        if (domain) {
            dispatch(updateAppStatus(domain + ' was successfully authorised'))
            lf('Check for DsynrFormatted Token')
            //Verify DsynrFormatted Cookies
            //@todo send DsynrFormatted Token in the API call. This will allow obscufy the token as it will be shuffled into an DsynrFormatted array.

            const token = unformatToken(cookies)
            if (token.length > 0) {
                lf('token present, although not verified. any failed ping should trigger reAuth..')

                dispatch(setAccApis(domain))

                dispatch(updateAppData('account', {token: token, isLogged: true}))
            } else {
                //token invalid
                dispatch(reAuthenticate('hydrateApp'))
            }
        }
    }
}
