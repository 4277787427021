import {Button, InputGroup} from "react-bootstrap";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {formControlReset} from "./actions";
import {formDataRecap} from "../form/actions";
import {DsynrFormOptions} from "../options";
import _AFormControlTypeText from "./_AFormControlTypeText";
import _AFormControlTypeOption from "./_AFormControlTypeOption";
import _AFormControlTypeSelect from "./_AFormControlTypeSelect";
import _AFormControlTypeDateTime from "./_AFormControlTypeDateTime";

export default function _AFormControl({...control}) {

    const dispatch = useDispatch()
    control = useSelector(state => state.forms[control.fid].controls[control.cid])

    let formControl

    const controlTypes = DsynrFormOptions.Form.Control.Types
    let conditionalDOMAttributes = {}
    switch (control.type) {
        case controlTypes.textarea:
            conditionalDOMAttributes.as = control.type
            break
        default:
            conditionalDOMAttributes.type = control.type
            break
    }

    switch (control.type) {
        case controlTypes.checkbox:
        case controlTypes.switch:
        case controlTypes.radio:
            formControl = <_AFormControlTypeOption conditionalAttributes={conditionalDOMAttributes} {...control}/>
            break
        case controlTypes.dateTime:
            formControl = <_AFormControlTypeDateTime {...control}/>
            break
        case controlTypes.select:
            formControl = <_AFormControlTypeSelect {...control}/>
            break
        default:
            formControl = <_AFormControlTypeText conditionalAttributes={conditionalDOMAttributes} {...control}/>
            break
    }

    if (control.isLabelConjoined) {
        formControl = <>
            <InputGroup>
                {
                    control.label && control.isLabelVisible && control.isLabelConjoined ?
                        <>
                            <InputGroup.Text className={control.labelCls}>
                                {control.label}
                            </InputGroup.Text>
                        </>
                        : ''
                }
                {formControl}
            </InputGroup>
        </>
    }

    return <>
        {formControl}
    </>
}
