import {lf} from "../../../DSYNR/Utils/debug";
import React from "react";
import {Button, ButtonGroup, Card} from "react-bootstrap";
import {BsThreeDots, GoPlus} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import ASpinner from "../../../app/ASpinner";
import {addNewItem} from "../../../_/actions";
import AddNewBtn from "../../../app/widgets/AddNewBtn";
import {changeScreen} from "../../../_/actions/ui";
import {AppOptions} from "../../../_/options";

export default function AFutureAppointmentsOverview() {

    const dispatch = useDispatch()

    lf('AFutureAppointmentsOverview')

    const icoAppointment = AppOptions.appointment.ico
    const futureAppointments = useSelector(state => state.app.data.futureAppointments)
    const totalFutureAppointments = Object.keys(futureAppointments).length
    let upcomingAppointments
    if (totalFutureAppointments > 0) {
        upcomingAppointments = Object.keys(futureAppointments).map((upcomingAppointment, i) => {
            const ua = futureAppointments[upcomingAppointment]
            return <Card key={i} className="border-0 rounded shadow-sm me-4 mt-4 float-start">
                <Card.Body>
                    <Card.Title>{ua.service}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{ua.dateTime}</Card.Subtitle>
                    <Card.Text>
                        {ua.duration} Session for {ua.user}
                    </Card.Text>
                    <Button variant={"light"} className="fs-4"><BsThreeDots/></Button>
                </Card.Body>
            </Card>
        })
    } else {
        upcomingAppointments =' '
            // upcomingAppointments = <p className="lead text-white">You don't have any upcoming appointments at the moment.
            {/*<br/><span className="btn-link hand" onClick={() => {*/}
            {/*    dispatch(addNewItem('appointment'))*/}
            {/*}}>Add an Appointment?</span>*/}
        // </p>
    }

    return <div className='d-table bg-darkGradient shadow-lg rounded-3 p-5 w-100'>
        <h2 className="fw-lighter display-5 text-white">
            <span className="text-white opacity-25">{icoAppointment}</span> You have {totalFutureAppointments > 0 ? totalFutureAppointments + ' ' : ' No '}
            Appointment{(totalFutureAppointments > 1 || totalFutureAppointments === 0) ? 's' : ''} Today
        </h2>
        <div className='d-table'>
            {upcomingAppointments || <ASpinner/>}
        </div>
        <ButtonGroup size={"lg"} className='mt-5'>
            <Button variant={"outline-light"}
                    onClick={() => {
                        dispatch(changeScreen('appointment'))
                    }}
            >View All Appointments</Button>
            <AddNewBtn what='appointment' label='Add a New Appointment'/>
        </ButtonGroup>
    </div>
}
