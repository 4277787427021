import React from "react";

/**
 * Use this template to combine, customise or extend one or more DsynrFormControls or make pre-defined components.
 * @note The widget uses default styles. Customisation options not yet defined.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AGenericWidget({body, footer, bgCls='bg-white shadow-lg'}) {
    return <div className={`rounded overflow-hidden ${bgCls}`}>
        {body}
        {
            footer === undefined ? '' :
                <>
                    <hr className='bg-info opacity-25 m-0'/>
                    <div className='p-3'>
                        {footer}
                    </div>
                </>
        }
    </div>
}
