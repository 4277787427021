import {Col, Container, Row} from "react-bootstrap";
import React from "react";

export default function AFormWithSummary({form, formSummary, ...props}) {
    return <Container className='bg-light p-5'>
        <Row>
            {
                props.isWizard ?
                    <>
                        <Col className='order-1 d-flex align-items-center'>
                            <div className='d-table mx-auto align-self-center'>
                                {form}
                            </div>
                        </Col>
                        <Col className='order-0 p-5 d-flex align-items-center' md={6}>
                            <div className='d-table mx-auto align-self-center'>
                                {formSummary}
                            </div>
                        </Col>
                    </>
                    :
                    <>
                        <Col className='order-1'>
                            {form}
                        </Col>
                        <Col className='order-0' md={6}>
                            {formSummary}
                        </Col>
                    </>
            }
        </Row>
    </Container>
}
