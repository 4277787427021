import {combineReducers} from "redux"
import {connectRouter} from 'connected-react-router'
import DsynrForms from "../../DSYNR/DsynrForm/_/reducers"
import DsynrApp from "./DsynrApp"
import DsynrPortals from "../../DSYNR/DsynrPortal/_/reducers";

// const createRootReducer = (history) => combineReducers({
const createRootReducer = () => combineReducers({
    // router: connectRouter(history), @todo do we need router?
    app: DsynrApp,
    forms: DsynrForms,
    portals: DsynrPortals,
})
export default createRootReducer
