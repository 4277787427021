import {lf} from "../../DSYNR/Utils/debug";
import {updateAppData, updateAppNow, updateAppStatus} from "./app";
import {updateObj} from "../../DSYNR/Utils/obj";
import {set, update} from "../actions";
import {fetchAPI} from "./api";

export const getServerUpdates = (URL, token, updateFrequency, isUpdating) => {
    lf('getServerUpdates...')
    return function (dispatch) {
        dispatch(updateAppStatus('Checking for updates...'))
        if (!isUpdating) {
            dispatch(updateAppNow('isUpdating', true))
            dispatch(fetchAPI(URL, token, data => {
                lf('.....updates fetched', data.data)
                const d = data.data
                for (const kname in d) {
                    // if (kname === 'notifications') {
                    //     dispatch(set('notifications', d[kname]))
                    // } else if (kname === 'futureAppointments') {
                    //     dispatch(updateFutureAppointments(d[kname]))
                    // } else {
                    //     dispatch(update('overview', {summary: d[kname]}))
                    //     if (kname === 'pref') {
                    //         dispatch(update('app', {currentScreen: data[kname]['defaultScreen']}))
                    //     }
                    // }
                    dispatch(updateAppData(kname, data[kname]))
                    // dispatch(set('data', updateObj(data, {[kname]: data[kname]})))
                }
                dispatch(updateAppNow('isUpdating', false))
                dispatch(updateAppStatus('App is up to date!'))
            }, err => {
                dispatch(updateAppStatus('Failed to update. Retrying in ' + (updateFrequency) / 60000 + ' Minutes'))
            }))
        }
    }
}
export const enableBackgroundServerUpdates = (URL, updateFrequency, isUpdating, token) => {
    lf('enableBackgroundServerUpdates')
    return function (dispatch) {
        setInterval(() => {
            dispatch(getServerUpdates(URL, token, updateFrequency, isUpdating))
        }, updateFrequency)
    }
}
export const updateNetworkStatus = (connection) => {
    lf('updateNetworkStatus', connection)
    return function (dispatch) {
        if (connection !== undefined && connection.rtt === 0) {
            dispatch(updateAppNow('isOnline', false))
            dispatch(updateAppStatus('You are offline'))
            // dispatch(update('network', {isOnline: false}))
        } else {
            dispatch(updateAppNow('isOnline', true))
            dispatch(updateAppStatus('You are now online'))
            // dispatch(update('network', {isOnline: true}))
        }
        dispatch(updateAppNow('netConnection', connection))
        // update('network', {connection: connection})
    }
}
export const toggleNetworkInfo = (show = true) => {
    return function (dispatch) {
        dispatch(updateAppNow('isConnectionInfoVisible', show))
        // dispatch(update('network', {isInfoVisible: show}))
    }
}
