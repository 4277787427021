import {lf} from "../../DSYNR/Utils/debug";
import {networkRequest} from "../../DSYNR/Utils/network";
import {reAuthenticate, updateAppData, updateAppNow, updateAppStatus} from "./app";
import {updateAccountAPIs} from "../utils";
import {set, update} from "../actions";

export const fetchAPI = (URI, token, onSubmitSuccess = null, onSubmitError = null) => {
    lf('fetchAPI', URI, token)
    return async function (dispatch) {
        //@todo dispatch('somthing like show loader...')
        let response = await networkRequest(URI, false, token)

        lf('fetchAPI+++++++++++++++', response)

        if (response.type === 'cors') {
            //Fetch succeeded, validate response
            if (response.status === 200) {
                onSubmitSuccess(response)
            } else {
                //403 other?
                lf(403)
                onSubmitError()
                dispatch(reAuthenticate())
            }
        } else {
            //Fetch failed, network issue
            // dispatch(networkFailure(formID, response))
            lf('network issue')
            onSubmitError()
        }
    }
}
export const apiFetched_records = data => {
    lf('apiFetched_records', data)
    const recordType = `${data.recordType}s`
    delete data.recordType
    lf(data, recordType, '...........data')
    return function (dispatch) {
        // dispatch(set([recordType], data))
        dispatch(updateAppData(recordType, data))
    }
}
export const apiFetched_options = data => {
    lf('apiFetched_options', data)
    return function (dispatch) {
        for (const cptName in data) {
            const cptData = data[cptName]
            // if (cptName === 'service' || cptName === 'prototype') {//prototypes are used to create new items
            //not using prototypes anymore
            // dispatch(set(`${cptName}s`, cptData))
            if (cptName === 'service') {
                let serviceo = {}
                for (const serviceID in cptData) {
                    let so = cptData[serviceID].options
                    serviceo[serviceID] = so === undefined ? {} : so//dont want undefined options to crash the forms' select
                }
                dispatch(update('options', {'serviceo': serviceo}))
                // dispatch(set('serviceo', serviceo))
            }

            if (cptName !== 'serviceo') {
                dispatch(update('options', {[cptName]: cptData})) //@todo consolidate service+serviceo
            }
        }
    }
}
export const apiFetched_ini = data => {
    lf('apiFetched_ini', data)
    return function (dispatch) {
        for (const kname in data) {

            lf(kname, data[kname], '..............................')
            dispatch(updateAppData([kname], data[kname]))

            if (kname === 'pref') {
                dispatch(updateAppNow('currentScreen', data[kname]['defaultScreen']))
            }
        }
        // dispatch(changeScreen('dashboard'))
    }
}
export const setAccApis = (domain) => {

    return function (dispatch) {
        updateAccountAPIs(domain)
        dispatch(updateAppStatus(<>Loading app data for <pre className='text-info'>{domain}</pre></>))
    }
}
