import {useDispatch, useSelector} from "react-redux";
import {formInitialise} from "./_/form/actions";
import {getForm, hasChildrenReact} from "./_/utils";
import _AFormSubmitBtnSet from "./_/form/_AFormSubmitBtnSet";
import _AFormWizard from "./_/wizard/_AFormWizard";
import _AFormProgress from "./_/form/_AFormProgress";

const {Form} = require("react-bootstrap");

/**
 * @see DsynrFormOptions.Form.Props for available formProps
 * @usage DsynrForm {...customFormProps}
 * @param form
 * @todo autoComplete bug?
 * @see https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
 * @returns {JSX.Element} Complete form including controls (with validation), wizard (optional) and submit/reset buttons
 * @constructor
 */
export default function DsynrForm({...form}) {

    const dispatch = useDispatch()

    if (!getForm(form.fid)) {
        dispatch(formInitialise(form))
    }

    form = useSelector(state => state.forms[form.fid])

    return <>
        {form.isVisible ?
            <Form className={form.isStyled ? form.cls : ''}>

                <_AFormProgress {...form}/>

                {
                    hasChildrenReact(form) && form.isWizard ?
                        <_AFormWizard {...form}/> : form.children
                }

                <_AFormSubmitBtnSet {...form}/>

            </Form> : ''
        }
    </>
}
