import {Button, ButtonGroup, Col, Container, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {IoCheckmarkDoneSharp, IoNotifications} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import {changeScreen, clearAllNotifications, hideNotificationPanel} from "../../_/actions/ui";
import {AppOptions} from "../../_/options";

export default function NotificationSlot() {

    const dispatch = useDispatch()

    const notifications = useSelector(state => state.app.data.notifications)
    const notificationPanel = AppOptions.notification
    const notificationKeys = Object.keys(notifications)
    const notificationsContent = notificationKeys.map(notification => {
        const n = notifications[notification]
        return <ListGroupItem key={notification} action className="py-3">
            <div className="d-flex w-100 align-items-center justify-content-between">
                <div>
                    <span className="fs-4 me-3">{notificationPanel.ico}</span>
                    <span className="lead">{n.title}</span>
                </div>
                <small>Day</small>
            </div>
            <div className="col-10 my-1">{n.content}</div>
        </ListGroupItem>
    })

    return <Container className='p-5'>

        <Row>
            <Col>

                <div className='text-center d-table mx-auto p-3 bg-secondary bg-gradient rounded-circle'>
                    <IoNotifications className="text-white mt-1 fs-2"/>
                </div>

                {notificationKeys.length === 0 ? <p className='text-center lead mt-4'>You have no new notifications.</p> : ''}

                <ButtonGroup className='mx-auto d-table my-4'>
                    {notificationKeys.length > 0 ?
                        <Button variant="outline-dark"
                                onClick={() => {
                                    dispatch(clearAllNotifications())
                                }}><IoCheckmarkDoneSharp/> Clear All</Button>
                        : ''
                    }
                    <Button variant={"secondary"}
                            onClick={() => {
                                dispatch(changeScreen('notification'))
                                dispatch(hideNotificationPanel())
                            }}
                    >View All Notifications</Button>
                </ButtonGroup>


                <ListGroup className="list-group-flush mt-3">
                    {notificationsContent}
                </ListGroup>

            </Col>
        </Row>

    </Container>
}
