import {lf} from "../../DSYNR/Utils/debug";
import {addNewItem} from "../../_/actions";
import {FaPlus} from "react-icons/all";
import {Button} from "react-bootstrap";
import React from "react";
import {useDispatch} from "react-redux";

export default function AddNewBtn({what, cls = '', label = false}) {
    lf('AddNewBtn')

    const dispatch = useDispatch()
    const className = label ? cls : ('addNewBtn ' + cls)
    const variant = label ? 'primary' : 'light'

    return <Button size={"lg"} variant={variant} className={className}
                   onClick={() => {
                       dispatch(addNewItem(what))
                   }}>
        {label ? label : <FaPlus/>}
    </Button>
}
