import {lf} from "../DSYNR/Utils/debug";
import React from "react";
import {DsynrFormOptions} from "../DSYNR/DsynrForm/_/options";
import {APIS, AppFormDefaults} from "./options";
import {updateObj} from "../DSYNR/Utils/obj";
import {getState} from "../DSYNR/Utils/react";

export const unformatToken = (dsynrFormattedTokens) => {
    lf('unformatToken', dsynrFormattedTokens)
    let dsynrAppCMSToken = ''

    const DSYNR_SALT = 'dsynr';
    const DSYNR_SALT_A = 'a';

    let dsynrFormattedTokensLength = 0
    dsynrFormattedTokens.map((dftSlice, i) => {
        if (
            dftSlice.charAt(dftSlice.length - 1) === DSYNR_SALT.charAt(i) ||
            dftSlice.charAt(dftSlice.length - 1) === DSYNR_SALT_A.charAt(0)
        ) {
            dftSlice = dftSlice.substr(2, dftSlice.length - 3)
            dsynrAppCMSToken += dftSlice
            dsynrFormattedTokensLength++
        }
    })
    if (dsynrFormattedTokensLength !== DSYNR_SALT.length + DSYNR_SALT_A.length) {
        return false
    }
    return dsynrAppCMSToken
}

export const getAppFormControlDefaults = () => {
    return {
        ...DsynrFormOptions.Form.Control.Props,
        ...AppFormDefaults.ControlProps
    }
}

export const updateApp = (app, propName, propVal) => {
    return updateObj(app, {[propName]: propVal})
}

const getAppProp = (propName, dataset) => {
    let appPropDataset = getState('app', propName)
    return dataset ? appPropDataset[dataset] : appPropDataset
}

export const updateAppProp = (propName, datasetName, data) => {
    let appPropData = getAppProp(propName)
    //@todo is this working safely? MOSTLY YES, ENSURE AND OPTIMISE
    let appPropDataSet = appPropData[datasetName]
    switch (typeof data) {
        case "object":
            // lf('updateAppProp.......@appPropDataSet OBJ', propName, appPropData, appPropDataSet, data)
            appPropDataSet = updateObj(appPropDataSet, data)
            break
        default:
            appPropDataSet = data
            // lf('updateAppProp.......@appPropDataSet STRING', appPropDataSet, data, typeof appPropDataSet)
    }
    return updateObj(appPropData, {[datasetName]: appPropDataSet})
    // return updateObj(appPropData, {[datasetName]: appPropDataSet})
    // lf('updateAppProp....@', propName, datasetName, data, appPropDataSet)
    // lf('appPropDataSet....@', appPropDataSet, typeof appPropDataSet, appPropData, typeof appPropData)
}

export const updateAppDataProp = (propName, propVal) => {
    return updateAppProp('data', propName, propVal)
}

export const updateAppOptionProp = (propName, propVal) => {
    return updateAppProp('options', propName, propVal)
}

/**
 * @use updateAppData for simplicity
 * @param propName
 * @param propVal
 * @returns {*}
 */
export const updateAppNowProp = (propName, propVal) => {
    return updateAppProp('now', propName, propVal)
}

export const getAppNow = datum => {
    return getAppProp('now', datum)
}

export const getAppData = (dataset) => {
    return getAppProp('data', dataset)
}

export const getAppOptions = dataset => {
    return getAppProp('options', dataset)
}

export const updateAccountAPIs = domain => {
    for (const slug in APIS) {
        APIS[slug] = 'https://' + domain + '/api/dsynr/' + slug
    }
}
