import {lf} from "../DSYNR/Utils/debug";
import {AppOptions} from "../_/options";
import {useSelector} from "react-redux";
import DsynrPortal from "../DSYNR/DsynrPortal";
import {DsynrPortalOptions} from "../DSYNR/DsynrPortal/_/options";

export default function NewPortals() {
    lf('NewPortals')

    //@todo use something like state.portals.new (will require refactoring) instead of state.portals to avoid re-rendering on every state change?
    const portals = useSelector(state => state.portals)

    const newScreens = Object.keys(AppOptions).map(screenName => {
        if (AppOptions[screenName] && AppOptions[screenName].new) {

            let size = 'xl'
            let isDismissible = false
            let isBackdropVisible = true
            let screenTitle = AppOptions[screenName].screenTitle

            if (screenName === 'user') {
                screenTitle = 'person'
                isDismissible = true
                isBackdropVisible = false
            } else {
                screenTitle = screenTitle.substr(0, screenTitle.length - 1)
            }

            const ico = AppOptions[screenName].ico
            const title = 'New ' + screenTitle

            const portalProps = {
                ...DsynrPortalOptions.Props,
                pid: screenName,
                title: title,
                description: screenName,
                ico: ico,
                isDismissible: isDismissible,
                isBackdropVisible: isBackdropVisible,
                // size: size,
                isClosable: true,
                isVisible: false,
                bodyCls: 'bg-light',
            }

            return <DsynrPortal key={screenName} {...portalProps}>
                {AppOptions[screenName].new}
            </DsynrPortal>
        }
    })
    return <>{newScreens}</>

    // const isNewAppointmentVisible = useSelector(state => state.portals.appointment.isNewVisible), isNewSessionVisible = useSelector(state => state.portals.session.isNewVisible),
    //     isNewHistoryVisible = useSelector(state => state.portals.history.isNewVisible), isNewAssessmentVisible = useSelector(state => state.portals.assessment.isNewVisible),
    //     isNewReportVisible = useSelector(state => state.portals.report.isNewVisible), isNewServiceVisible = useSelector(state => state.portals.service.isNewVisible),
    //     isNewGCVisible = useSelector(state => state.portals.gc.isNewVisible), isNewVoucherVisible = useSelector(state => state.portals.voucher.isNewVisible),
    //     isNewOrderVisible = useSelector(state => state.portals.order.isNewVisible), isNewInvoiceVisible = useSelector(state => state.portals.invoice.isNewVisible),
    //     isNewUserVisible = useSelector(state => state.portals.user.isNewVisible);

    // return <>
    //     <AModal isVisible={isNewAppointmentVisible} body={AppOptions.appointment.new}/>
    //     <AModal isVisible={isNewSessionVisible} body={AppOptions.session.new}/>
    //     <AModal isVisible={isNewHistoryVisible} body={AppOptions.history.new}/>
    //     <AModal isVisible={isNewAssessmentVisible} body={AppOptions.assessment.new}/>
    //     <AModal isVisible={isNewReportVisible} body={AppOptions.report.new}/>
    //     <AModal isVisible={isNewServiceVisible} body={AppOptions.service.new}/>
    //     <AModal isVisible={isNewGCVisible} body={AppOptions.gc.new}/>
    //     <AModal isVisible={isNewVoucherVisible} body={AppOptions.voucher.new}/>
    //     <AModal isVisible={isNewOrderVisible} body={AppOptions.order.new}/>
    //     <AModal isVisible={isNewInvoiceVisible} body={AppOptions.invoice.new}/>
    //     <AModal isVisible={isNewUserVisible} body={AppOptions.user.new}/>
    // </>
}
