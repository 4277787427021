import {DsynrPortalOptions} from "./options";
import {lf} from "../../Utils/debug";
import {updateObj} from "../../Utils/obj";
import {updatePortalProp, updateState} from "./utils";

export default function DsynrPortals(portals = {}, action) {
    if (action.payload) {

        const payload = action.payload
        lf('payload@REDUCER_Portals:::', payload)

        let portal, pid
        if (payload.portal !== undefined) {

            portal = payload.portal
            pid = portal.pid

            switch (action.type) {

                case DsynrPortalOptions.Actions.enlistPortal:
                    return updateObj(portals, {[pid]: payload.portal})

                case DsynrPortalOptions.Actions.updatePortalProp:
                    return updateState(portals, pid,
                        updatePortalProp(portals[portal.pid], payload.prop, payload.val)
                    )
            }
        }

    }
    return portals
}
