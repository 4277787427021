import {lf} from "../../DSYNR/Utils/debug";
import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import AFutureAppointmentsOverview from "../appointment/widgets/AFutureAppointmentsOverview";
import AOverview from "../../app/widgets/AOverview";
import AccountWidget from "../../app/widgets/AAccountOverview";
import AGoTo from "../../app/widgets/AGoTo";
import {AGreeting} from "../../app/AGreeting";
import {useDispatch, useSelector} from "react-redux";
import ABusinessOverviewGraph from "../../app/widgets/ABusinessOverviewGraph";
import AAppointmentsGraph from "../appointment/widgets/AAppointmentsGraph";
import {addNewItem} from "../../_/actions";
import {changeScreen} from "../../_/actions/ui";

export default function Home() {

    lf('Home');

    const dispatch = useDispatch()

    const fname = useSelector(state => state.app.data.profile.fname)
    const dashboardTitle = 'You are signed into the account of ' + useSelector(state => state.app.data.account.name)

    return <Container className='pt-5'>
        <Row className='mt-5 mb-4'>
            <Col>
                <h1 className='display-1 fw-bold m-0'><AGreeting name={fname}/></h1>
                <p className="lead text-muted">{dashboardTitle}</p>
            </Col>
        </Row>

        <Row>
            <Col xs={12}>
                <h2 className="fw-lighter display-3 text-secondary my-4">Today's Summary</h2>
            </Col>
            <Col>
                <AFutureAppointmentsOverview/>
            </Col>
            <Col lg={4}>
                <AccountWidget/>
            </Col>
        </Row>

        <Row className="p-5 mt-5 bg-white shadow-sm rounded-3">
            <Col xs={12}>
                <h2 className="fw-lighter display-3 text-decoration-underline">Business Insights</h2>
                <p className='lead fs-3 text-secondary mb-4'>See how your business is performing overtime.
                    <br/>
                    <Button variant={"link"} className='ps-0'
                            onClick={
                                () => {
                                    dispatch(changeScreen('insights'))
                                }
                            }>View More Insights</Button>
                </p>
            </Col>
            <Col>
                <ABusinessOverviewGraph/>
            </Col>
            <Col md={1}></Col>
            <Col md={7}>
                <AAppointmentsGraph/>
            </Col>
        </Row>

        <Row className="p-5">
            <Col xs={12}>
                <h2 className="fw-lighter display-3 text-decoration-underline mb-4">Quick Links</h2>
            </Col>
            <Col className='ps-0'>
                <AOverview/>
            </Col>
            <Col className='pe-0'>
                <AGoTo/>
            </Col>
        </Row>
    </Container>
}
