import {lf} from "../../DSYNR/Utils/debug";
import {AppOptions} from "../../_/options";
import {Card, ListGroup, ListGroupItem} from "react-bootstrap";
import ASpinner from "../ASpinner";
import React from "react";
import {CgArrowLongRightC} from "react-icons/all";
import {useDispatch} from "react-redux";
import ADashboardWidget from "../../templates/ADashboardWidget";
import {changeScreen} from "../../_/actions/ui";

export default function AGoTo() {
    lf('AGoTo')

    const dispatch = useDispatch()

    const gotoItems = Object.keys(AppOptions).map((screen, i) => {
        if (AppOptions[screen] && AppOptions[screen].hasPage) {//ensure the screen is not yet undefined
            const screenTitle = AppOptions[screen].screenTitle
            const ico = AppOptions[screen].ico
            return <ListGroupItem key={i} action>
                <div className='p-3' onClick={() => {
                    dispatch(changeScreen(screen))
                }}>
                    <span className="fs-4 opacity-25">{ico}</span>
                    <span className="lead">
                    <span className="ms-3 me-2 text-muted">{screenTitle}</span>
                </span>
                </div>
            </ListGroupItem>
        }
    })

    return <ADashboardWidget
        title={<>Go To <span className="text-secondary opacity-50"><CgArrowLongRightC/></span></>}
        summary={gotoItems}
    />
}
