import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {lf} from "../../DSYNR/Utils/debug";
import {getState} from "../../DSYNR/Utils/react";
import AppointmentFormSummary from "./AppointmentFormSummary";
import DsynrForm from "../../DSYNR/DsynrForm";
import DsynrFormControl from "../../DSYNR/DsynrForm/DsynrFormControl";
import AFormSelectUser from "../user/widgets/AFormSelectUser";
import AFormService from "../service/widgets/AFormService";
import AFormPaymentSection from "../order/AFormPaymentSection";
import AFormWithSummary from "../../templates/AFormWithSummary";
import AFormSelectDateTime from "./widgets/AFormSelectDateTime";
import AFormSectionHeading from "../../templates/AFormSectionHeading";
import {DsynrFormOptions} from "../../DSYNR/DsynrForm/_/options";
import {updateAppStatus} from "../../_/actions/app";
import {APIS} from "../../_/options";

export default function NewAppointmentForm() {
    lf('NewAppointmentForm')

    // const form = useSelector(state => state.forms[formID])
    // lf('form................................................................', formID, form)
    const formPostURL = APIS.add

    const dispatch = useDispatch()

    const formOptions = DsynrFormOptions.Form
    const controlTypes = formOptions.Control.Types
    const controlProps = formOptions.Control.Props

    const token = useSelector(state => state.app.data.account.token)
    const services = useSelector(state => state.app.options.service)


    //@todo fix @forms: value is not registered unless change is triggered, so specifying default val will actually result in empty val! skipping for now to force user to manually select one of the options
    // const defaultService = useSelector(state => state.app.pref.defaultService)
    const defaultService = 0

    //@todo if the select value isnt changed, the form data skips it. set default select value
    //@todo isRequired doesnt work @select?

    //@todo sync with google cal, set times
    //@todo sync date of time with initialDate upon date change

    const formSubmissionSuccessful = () => {
        lf('formSubmissionSuccessful', getState('forms'))
        // alert('formSubmissionSuccessful')
    }

    const formSubmissionError = () => {
        lf('formSubmissionError', getState('forms'))
        dispatch(updateAppStatus('onSubmitError...'))
    }

    const fid = 'newAppointmentForm'

    const formProps = {
        ...formOptions.Props,
        fid: fid,
        token: token,
        submitURL: formPostURL,
        isSubmitBtnVisible: false,
        resetBtnTxt: 'Cancel & Restart',
        progressBarRootCls: 'bg-white rounded my-3 overflow-hidden',
        onSubmitSuccess: formSubmissionSuccessful,
        onSubmitError: formSubmissionError,
        // isWizard: false,
        // isVisible: false,
        cls: 'p-5x',
    }

    return <AFormWithSummary
        {...formProps}
        form={
            <DsynrForm {...formProps}>

                <section className='p-5'>
                    <AFormSectionHeading heading='Who is this appointment for?'/>
                    <AFormSelectUser fid={fid}/>
                </section>

                <section className='p-5'>
                    <AFormSectionHeading heading='What is this appointment for?'/>
                    <AFormService fid={fid}/>
                </section>

                <section className='p-5'>
                    <AFormSectionHeading heading='When is this appointment?'/>
                    <AFormSelectDateTime fid={fid}/>
                </section>

                <AFormPaymentSection/>

            </DsynrForm>
        }
        formSummary={
            <AppointmentFormSummary {...formProps}/>
        }
    />
}
