import {FaDollarSign} from "react-icons/all";
import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {addNewItem} from "../../_/actions";
import {useDispatch} from "react-redux";
import AHint from "../../app/AHint";

export default function AMakePayment({amount}) {

    const dispatch = useDispatch()

    return <Container>
        <Row>
            <Col className="shadow-lg text-center py-3 rounded-start">
                <span className='display-5 text-muted'>
                        <FaDollarSign/>{amount}
                    </span>
            </Col>
            <Col className='bg-darkGradient text-white hand text-decoration-underline fs-3 d-flex align-items-center rounded-end'
                 onClick={() => {
                     dispatch(addNewItem('order'))
                 }}>
                <span className='d-table mx-auto align-self-center'>Pay Now</span>
            </Col>
        </Row>
        <Row>
            <Col className='mt-5'>
                <hr className='mt-0 bg-info'/>
                <Button variant={"link"}
                        onClick={() => {
                        }}>
                    Confirm the appointment without payment
                </Button>
                <AHint msg='You can decide the payment options later.'/>
            </Col>
        </Row>
    </Container>

}
