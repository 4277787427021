import React from "react";
import {FaHandPointRight, IoIosCheckmarkCircle} from "react-icons/all";
import AFormSummaryRow from "./AFormSummaryRow";

export default function AFormSummaryFieldPending({text}) {
    return <AFormSummaryRow
        text={text} textCls='opacity-75'
        textSize='fs-6'
        ico={<FaHandPointRight/>}
    />
}
