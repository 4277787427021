import {Col, Container, Image, Row} from "react-bootstrap";
import {lf} from "../DSYNR/Utils/debug";
import {useDispatch, useSelector} from "react-redux";
import {BiWifi, BsWifi, FaWifi, ImConnection, MdSignalWifi4Bar, RiSignalWifiOffFill} from "react-icons/all";
import {AppOptions} from "../_/options";
import {updateAppStatus} from "../_/actions/app";

export default function AppFooter() {
    lf('AppFooter');

    const dispatch = useDispatch()

    const dsynr = AppOptions.dsynr
    const currentStatus = useSelector(state => state.app.now.currentStatus)

    return <Container as={"footer"} fluid className="pt-5">
        <Row className="bg-light">
            <Col xs={11}>
                <code className="text-muted">{currentStatus}</code>
            </Col>
            <Col>
                <a href={dsynr.url} className="text-dark d-table mx-auto" target="dsynr">
                    <Image src={dsynr.logo} alt="Dsynr" width="25" height="25"/>
                </a>
            </Col>
        </Row>
    </Container>
}
