import {lf} from "../DSYNR/Utils/debug";
import {portalToggleVisibility} from "../DSYNR/DsynrPortal/_/actions";
import {getPortal} from "../DSYNR/DsynrPortal/_/utils";

//@todo optimise, delete unused actions
export const ACTIONS = {
    SET: 'SET',
    update: 'update',
    exitCurrentScreen: 'exitCurrentScreen',
    updateFutureAppointments: 'updateFutureAppointments',
    updateScreenBody: 'updateScreenBody',
    loadScreen: 'loadScreen',
    changeScreen: 'changeScreen',
    viewItem: 'viewItem',
    saveItem: 'saveItem',
    apiFetched: 'apiFetched',
    iniFetched: 'iniFetched',
    fetchAPI: 'fetchAPI',
    showNotificationPanel: 'showNotificationPanel',
    hideNotificationPanel: 'hideNotificationPanel',
    clearAllNotifications: 'clearAllNotifications',

}
export const set = (what, val) => {
    lf('set', what)
    return {
        type: ACTIONS.SET,
        payload: {
            what: what,
            val: val,
        }
    }
}

export const update = (what, val) => {
    lf('update', val)
    return {
        type: ACTIONS.update,
        payload: {
            what: what,
            val: val,
        }
    }
}

export const addNewItem = (what) => {
    lf('addNewItem', what)
    return function (dispatch) {
        dispatch(portalToggleVisibility(getPortal(what)))
    }
}

// export const viewItems = (itemType) => {
//     lf('viewItems', itemType)
//     return {
//         type: ACTIONS.viewItems,
//         payload: {
//             itemType: itemType
//         }
//     }
// }
// export const ping = (token, onSuccess = null) => {
//     lf('ping', token)
//     return async function (dispatch) {
//         let response = await networkRequest(APIS['ping'], false, token)
//
//         lf('+++++++++++++++', response)
//
//         if (response.type === 'cors') {
//             //Fetch succeeded, validate response
//             if (response.status !== 200) {
//                 //403 other?
//                 lf(403)
//                 dispatch(reAuthenticate())
//             } else {
//                 onSuccess()
//             }
//         } else {
//             //Fetch failed, network issue
//             // dispatch(networkFailure(formID, response))
//             lf('network issue')
//             lf('network issue during ping...')
//
//         }
//     }
// }

// export const apiFetched = (name, data) => {
//     lf('apiFetched', name, data)
//     return {
//         type: ACTIONS.apiFetched,
//         payload: {
//             name: name,
//             data: data
//         }
//     }
// }

// export const updateFutureAppointments = (data) => {
//     lf('updateFutureAppointments', data)
//     return {
//         type: ACTIONS.updateFutureAppointments,
//         payload: {
//             data: data
//         }
//     }
// }


