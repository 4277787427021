import React from "react";
import {IoIosCheckmarkCircle} from "react-icons/all";
import AFormSummaryRow from "./AFormSummaryRow";

export default function AFormSummaryFieldDone({text, label}) {
    return <AFormSummaryRow
        text={
            label ? <>{text}
                <br/>
                <span className='text-muted opacity-75 d-table mt-2 mb-3 pb-1 ms-4 ps-1'>{label}</span>
            </> : text
        }
        // textSize='fs-6'
        textCls='text-success opacity-75'
        ico={<IoIosCheckmarkCircle/>} icoVariant='success'
    />
}
